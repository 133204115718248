import { APP_SCHEME, callAppScheme } from '@tkl-packages/constants/appScheme';
import { KAKAO_JAVASCRIPT_KEY } from '@tkl-packages/constants/share';
import { isAndroid, isAppWeb, isIos } from '@tkl-packages/sdk/src/validations';
import ClipboardJS from 'clipboard';
import { ShareParams } from './convertShareParams';

const popupWindow = (url: string, title: string, width: number, height: number, scrollbars: string) => {
  const left = screen.width / 2 - width / 2;
  const top = screen.height / 2 - height / 2;
  window.open(
    url,
    title,
    `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=${scrollbars},resizable=no,copyhistory=no,width=${width},height=${height},top=${top},left=${left}`
  );
};

export const shareOnFacebook = ({ url, title }: ShareParams) => {
  if (isAppWeb() && isIos()) {
    callAppScheme(APP_SCHEME.SHARE, { uri: encodeURIComponent(`http://www.facebook.com/sharer/sharer.php?u=${url}`) });
    return;
  }

  if (isAppWeb() && isAndroid()) {
    callAppScheme(APP_SCHEME.SHARE, { uri: `http://www.facebook.com/sharer/sharer.php?u=${url}` });
    return;
  }

  popupWindow(`http://www.facebook.com/sharer/sharer.php?u=${url}`, title, 600, 600, 'no');
};

export const shareOnKakao = ({ url, title, image }: ShareParams) => {
  if (isAppWeb()) {
    callAppScheme(APP_SCHEME.KAKAO_SHARE, { title, imgurl: image, mobilelink: url, pclink: url });
    return;
  }

  !Kakao.isInitialized() && Kakao.init(KAKAO_JAVASCRIPT_KEY);
  Kakao.Link.sendDefault({
    objectType: 'feed',
    content: {
      title: title,
      imageUrl: image,
      link: {
        mobileWebUrl: url,
        webUrl: url
      }
    },
    installTalk: true
  });
};

export const shareOnLine = ({ url, title }: ShareParams) => {
  const params = encodeURIComponent(url);
  if (isAppWeb()) {
    window.location.href = `https://line.me/R/share?text=${params}`;
    return;
  }

  popupWindow(`https://social-plugins.line.me/lineit/share?url=${params}`, title, 800, 441, 'no');
};

export const shareOnBand = ({ url, title }: ShareParams) => {
  const params = `${encodeURIComponent(title)}&route=${encodeURIComponent(url)}`;
  if (isAppWeb()) {
    callAppScheme(APP_SCHEME.BAND_SHARE, { text: params });
    return;
  }

  popupWindow(`https://band.us/plugin/share?body=${params}`, title, 800, 441, 'no');
};

export const shareOnTwitter = ({ url, title }: ShareParams) => {
  const productUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(
    title
  )}`;
  if (isAppWeb()) {
    window.location.href = productUrl;
    return;
  }

  popupWindow(productUrl, title, 800, 441, 'no');
};

export const shareOnClipboard = (message?: string) => {
  const clipboard = new ClipboardJS('.url');
  clipboard.on('success', () => {
    alert(message || '복사하였습니다.');
    clipboard.destroy();
  });
};
