/**
 * TODO:운영팀
 * - apps/ticketlink/src/components/HTMLHeader/NormalHeader.tsx와 주석 유무 차이입니다.
 * 기존에 존재하는 path="mobile/ticket"의 코드는 Channel mobile과 동일해보여서, 그대로 사용하고, Channel pc는 새로 추가하기 때문에, 동일하게 열어두었습니다.
 * 전체 영향 끼치는 부분들은(GoogleAnalytics, <OgTag/> ) 분석하지 않아 주석처리하였습니다.
 * TODO:인제팀 문제 생기면 확인 후, 열면 될 것 같습니다.
 */

import { FC, PropsWithChildren } from 'react';
import { GoogleAnalytics } from '@tkl-packages/components/src/ticketlink/GA';
import { OgTag } from '@tkl-packages/components/src/ticketlink/OgTag';

const NormalHeaderGlobal: FC<
  PropsWithChildren<{
    disableGoogleAnalytics?: boolean;
  }>
> = ({ children, disableGoogleAnalytics }) => {
  return (
    <>
      <OgTag />
      {!disableGoogleAnalytics && <GoogleAnalytics />}
      {children}
    </>
  );
};

export default NormalHeaderGlobal;
