import { FC, PropsWithChildren } from 'react';
import { Footer } from '@tkl-packages/components/src/ticketlink/Footer';
import { HeaderGlobal } from '@tkl-packages/components/src/ticketlink/Header';
import { Modals } from '@tkl-packages/components/src/ticketlink/Modal';

const LayoutGlobal: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <div className="common_wrap">
        <HeaderGlobal />
        {children}
        <Footer />
      </div>
      <Modals />
    </>
  );
};

export default LayoutGlobal;
