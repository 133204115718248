import { CSSProperties } from 'react';
import { isAppWeb } from '@tkl-packages/sdk/src/validations';

interface props {
  baseStyle?: CSSProperties;
}

export const getPoductToolbarUtilStyle = ({ baseStyle = {} }: props = {}): CSSProperties => {
  if (isAppWeb() && !/\/product\/[0-9]/.test(location.pathname)) {
    return {
      ...baseStyle,
      bottom: 20
    };
  }

  return { ...baseStyle };
};
