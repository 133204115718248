import { useQuery } from '@tanstack/react-query';
import { convert, filterByExposureDates } from '@tkl-apps/ticketlink/src/utils/category';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { CATEGORY } from '@tkl-packages/constants/category';
import { GnbCategory, GnbType, CategoryComponentType } from '@tkl-packages/models';
import { atom, useAtomValue } from 'jotai';

// export const [exhibitionCategoriesAtom] = atomsWithQuery(() => ({
//   queryKey: [QUERY_KEY.CATEGORY],
//   queryFn: async () => {
//     const { hostname } = location;
//     let phase = hostname
//       .replace('.ticketlink.co.kr', '')
//       .replace('-m', '')
//       .replace('-appweb', '')
//       .replace('-facility', '');
//     phase = phase === 'localhost' ? 'local' : phase;
//
//     const targets = ['local', 'new', 'alpha', 'alpha2', 'beta', 'beta2', 'stage'];
//     if (!targets.includes(phase)) {
//       phase = 'release';
//     }
//
//     const response = await fetch(`https://tketlink.dn.toastoven.net/gnb/${phase}/gnb.json`);
//     const categories = await response.json();
//
//     return filterByExposureDates(categories);
//   }
// }));
// exhibitionCategoriesAtom.debugLabel = 'exhibitionCategoriesAtom';
export const useExhibitionCategories = () => {
  return useQuery({
    queryKey: [QUERY_KEY.CATEGORY],
    queryFn: async () => {
      const { hostname } = location;
      let phase = hostname
        .replace('.ticketlink.co.kr', '')
        .replace('-m', '')
        .replace('-appweb', '')
        .replace('-facility', '');
      phase = phase === 'localhost' ? 'local' : phase;

      const targets = ['local', 'new', 'alpha', 'alpha2', 'beta', 'beta2', 'stage'];
      if (!targets.includes(phase)) {
        phase = 'release';
      }

      const response = await fetch(`https://tketlink.dn.toastoven.net/gnb/${phase}/gnb.json`);
      const categories = await response.json();

      return filterByExposureDates(categories);
    }
  });
};

// export const categorySelector = atom(async (get) => {
//   const categories = await get(exhibitionCategoriesAtom);
//   const sportsCategory = categories.find((category: GnbCategory) => category.categoryId === CATEGORY.SPORTS);
//   const performanceCategory = categories.find((category: GnbCategory) => category.categoryId === CATEGORY.PERFORMANCE);
//   const exhibitionCategory = categories.find((category: GnbCategory) => category.categoryId === CATEGORY.EXHIBITION);
//
//   return {
//     sportsSubCategories: sportsCategory?.subGnbs || [],
//     performanceCategories: performanceCategory,
//     performanceSubCategories: performanceCategory?.subGnbs || [],
//     exhibitionCategories: exhibitionCategory,
//     exhibitionSubCategories: exhibitionCategory?.subGnbs || []
//   };
// });
// categorySelector.debugLabel = 'categorySelector';
export const useCategoryEx = () => {
  const { data: categories } = useExhibitionCategories();

  const performanceCategory = categories?.find((category: GnbCategory) => category.categoryId === CATEGORY.PERFORMANCE);
  const exhibitionCategory = categories?.find((category: GnbCategory) => category.categoryId === CATEGORY.EXHIBITION);

  const sportsCategory = categories?.find((category: GnbCategory) => category.categoryId === CATEGORY.SPORTS);

  return {
    sportsSubCategories: sportsCategory?.subGnbs || [],
    performanceCategories: performanceCategory,
    performanceSubCategories: performanceCategory?.subGnbs || [],
    exhibitionCategories: exhibitionCategory,
    exhibitionSubCategories: exhibitionCategory?.subGnbs || [],
    categories
  };
};

export const gnbTypeAtom = atom<GnbType>(CATEGORY.PERFORMANCE);
gnbTypeAtom.debugLabel = 'gnbTypeAtomAtom';

// export const categoriesAtom = atom(async (get) => {
//   const gnbType = get(gnbTypeAtom);
//   const categories = await get(exhibitionCategoriesAtom);
//
//   return convert(categories.find((category: GnbCategory) => category.categoryId === gnbType)!);
// });
// categoriesAtom.debugLabel = 'categoriesAtom';
export const useCategories = () => {
  const gnbType = useAtomValue(gnbTypeAtom);
  const { data: categories } = useExhibitionCategories();

  return convert(categories?.find((category: GnbCategory) => category.categoryId === gnbType)!);
};

export const categoryAtom = atom<CategoryComponentType | null>(null);
categoryAtom.debugLabel = 'categoryAtom';

export const subCategoriesAtom = atom((get) => {
  const category = get(categoryAtom);
  return category ? category.subCategory : [];
});
subCategoriesAtom.debugLabel = 'subCategoriesAtom';

export const subCategoryAtom = atom<CategoryComponentType | null>(null);
subCategoryAtom.debugLabel = 'subCategoryAtom';
