import { Product } from '@tkl-packages/models';
import { GlobalView } from '@tkl-packages/sdk/src/utils';

export type ShareParams = {
  url: string;
  title: string;
  image: string;
};

const getShareUrl = () => {
  const globalPath = GlobalView.isGlobal() ? `/global/${GlobalView.getGlobalLocale()}` : '';
  if (location.port) {
    return `${location.protocol}//${location.hostname.replace(/(link-appweb|appweb)/, 'm')}:${location.port}${globalPath}`;
  }

  return `${location.protocol}//${location.hostname.replace(/(link-appweb|appweb)/, 'm')}${globalPath}`;
};

const getProductShareUrl = (productId: string) => `${getShareUrl()}/product/${productId}`;

const getSportPlanningShareUrl = (planningEventId?: string, categoryName?: string) => {
  const baseUrl = `${getShareUrl()}/sports/${categoryName}/planning-event`;
  return planningEventId ? `${baseUrl}/${planningEventId}` : baseUrl;
};

const getPlanningShareUrl = (planningEventId?: string) => {
  const baseUrl = `${getShareUrl()}/planning-event`;
  return planningEventId ? `${baseUrl}/${planningEventId}` : baseUrl;
};

const getShareTitle = (productName: string) => `[티켓링크] ${productName}`;

export const product2ShareParams = (product?: Product): ShareParams => {
  if (!product) {
    return {
      url: '',
      title: '',
      image: ''
    };
  }

  return {
    url: getProductShareUrl(product?.productId || ''),
    title: getShareTitle(product?.productName || ''),
    image: `https:${product?.productImagePath || ''}`
  };
};

export const planning2ShareParams = ({
  planningEventId,
  categoryName,
  planningEventName,
  imageUrl
}: {
  planningEventId: string;
  categoryName?: string;
  planningEventName: string;
  imageUrl: string;
}): ShareParams => {
  if (categoryName) {
    return {
      url: getSportPlanningShareUrl(planningEventId, categoryName),
      title: getShareTitle(planningEventName),
      image: imageUrl
    };
  }

  return {
    url: getPlanningShareUrl(planningEventId),
    title: getShareTitle(planningEventName),
    image: imageUrl
  };
};
