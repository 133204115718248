import { useContext } from 'react';
import { LoginContext } from '@tkl-apps/ticketlink/src/components/Context/Login';
import { DeviceAuthMobile, WaitingReserveModalMobile } from '@tkl-apps/ticketlink/src/components/Guide';
import { ReserveButton } from '@tkl-apps/ticketlink/src/components/Product';
import { moveToAdult, openSportsReservePopup } from '@tkl-apps/ticketlink/src/utils/product';
import { Modal } from '@tkl-packages/components/src/ticketlink/Modal';
import { APP_SCHEME, appScheme } from '@tkl-packages/constants/appScheme';
import { MODAL_KEY } from '@tkl-packages/constants/modal';
import { SPORTS_ICON_INFO } from '@tkl-packages/constants/sports';
import useModal from '@tkl-packages/hooks/src/useModal';
import { CertifiedDeviceStatus, ScheduleMatch } from '@tkl-packages/models';
import { GlobalView } from '@tkl-packages/sdk/src/utils';
import { isWeb } from '@tkl-packages/sdk/src/validations';
import dayjs from 'dayjs';
import '@tkl-packages/sdk/src/utils/date';

interface props {
  match: ScheduleMatch;
  certifiedDeviceStatusCode: CertifiedDeviceStatus;
  isAdultAuth?: boolean;
}

const MatchCardMobile = ({ match, certifiedDeviceStatusCode, isAdultAuth }: props) => {
  const { homeTeam, awayTeam } = match;
  const { needLogin } = useContext(LoginContext);
  const { open, close } = useModal(MODAL_KEY.WAITING_RESERVE);
  const { open: openScheduleNotice, close: closeScheduleNotice } = useModal(MODAL_KEY.SCHEDULE_NOTICE);
  const { open: openDeviceAuthNotice, close: closeDeviceAuthNotice } = useModal(MODAL_KEY.DEVICE_AUTH_NOTICE);

  const openReservePopup = () => {
    openSportsReservePopup({
      scheduleId: match.scheduleId.toString(),
      productId: match.productId.toString()
    });
  };

  const onReserve = needLogin(() => {
    if (match.fanclubYn === 'Y') {
      // TODI:II 팬클럽에 대한 추가 확인 필요_백엔드 작업중
      alert('팬클럽/멤버십 인증 후 예매가 가능합니다.');
    } else if (!GlobalView.isGlobal() && !isAdultAuth && match.adultYn) {
      moveToAdult();
    } else if (match.notice) {
      openScheduleNotice(
        <Modal
          title="예매안내"
          buttonName="확인"
          onClose={() => {
            closeScheduleNotice();
            openReservePopup();
          }}
        >
          <div className="modal_notice" dangerouslySetInnerHTML={{ __html: match.notice.replaceAll('\n', '<br>') }} />
        </Modal>
      );
    } else {
      openReservePopup();
    }
  });

  const onOpenWatingReservationModal = () => {
    open(<WaitingReserveModalMobile info={match.waitingReservation} onClose={close} />);
  };

  const onClickDeviceAuthModal = () => {
    openDeviceAuthNotice(<DeviceAuthMobile onClose={closeDeviceAuthNotice} />);
  };

  return (
    <>
      <div className="match_card">
        <div className="match_card_visual">
          {match.teamMatchUse ? (
            <div className="match_team_group">
              <div className="match_team">
                <div className="match_team_imgbox">
                  <img
                    src={homeTeam?.logoImagePath}
                    alt={homeTeam?.teamName}
                    className="match_team_img"
                    aria-hidden="true"
                  />
                </div>
                <span className="match_team_name">{homeTeam?.teamShortName}</span>
              </div>
              <span className="match_versus">
                <span className="blind">vs</span>
              </span>
              <div className="match_team">
                <div className="match_team_imgbox">
                  <img
                    src={awayTeam?.logoImagePath}
                    alt={awayTeam?.teamName}
                    className="match_team_img"
                    aria-hidden="true"
                  />
                </div>
                <span className="match_team_name">{awayTeam?.teamShortName}</span>
              </div>
            </div>
          ) : (
            <img
              src={match.gameTitleImagePath ?? match.matchThumbnailPath}
              alt={match.matchTitle}
              className="match_thmbnail"
            />
          )}
        </div>

        <div className="match_card_info">
          {SPORTS_ICON_INFO[match.categoryId] && (
            <span className={`match_card_icon icon_${SPORTS_ICON_INFO[match.categoryId].code}`}>
              <span className="blind">{SPORTS_ICON_INFO[match.categoryId].name}</span>
            </span>
          )}
          <div className="match_card_flag">
            <div className="flag_area">
              {dayjs(new Date(match.reserveOpenDate)).tz().isToday() && (
                <span className="flag flag_today">오늘오픈</span>
              )}
            </div>
          </div>
          {match.matchTitleUse && (
            <>
              <span
                className="match_card_title"
                style={{
                  ...(match.gameTitleEmphasisUse && {
                    display: 'inline',
                    background: `linear-gradient(#FFFFFF00 60%, ${match.gameTitleEmphasisColorCode} 40%, ${match.gameTitleEmphasisColorCode} 100%)`
                  })
                }}
              >
                {match.matchTitle}
              </span>
            </>
          )}
          <span className="match_card_date">
            {dayjs(new Date(match.scheduleDate)).tz().format('YYYY.MM.DD (dd) HH:mm')}
          </span>
          <span className="match_card_place">{match.venueName}</span>
          <div className="match_card_gate">
            {match.captchaUse && (
              <a
                href={isWeb() ? '/product/guide/captcha' : appScheme(APP_SCHEME.CAPTCHA)}
                target={isWeb() ? '_blank' : '_self'}
                className="btn_guide_gate btn_clean_reserve"
                rel="noreferrer"
              >
                클린예매
              </a>
            )}
            {match.waitingReservation?.waitingReservationUse && (
              <a onClick={onOpenWatingReservationModal} className="btn_guide_gate btn_waiting_reserve">
                취소표대기
              </a>
            )}
            {match.authReinforceYn && (
              <a onClick={onClickDeviceAuthModal} className="btn_guide_gate btn_device_auth">
                기기 인증
              </a>
            )}
          </div>
        </div>
        <div className="match_card_btnarea">
          <div className="common_btn_box">
            <ReserveButton
              isAuthReinforced={match.authReinforceYn}
              saleStatus={match.reserveButtonStatus}
              className="btn_medium"
              onReserve={onReserve}
              reserveOpenDate={match.reserveOpenDate}
              certifiedDeviceStatusCode={certifiedDeviceStatusCode}
              scheme="ticketlink://sportsCategory?categoryId=137"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MatchCardMobile;
