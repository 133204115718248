import { createContext, PropsWithChildren } from 'react';
import useLogin from '@tkl-packages/hooks/src/useLogin';

interface loginContextProps {
  isLoggedIn: boolean;
  goLogin: () => void;
  needLogin: (fn: Function) => (...param: any) => void;
  isFetchedAfterMount: boolean;
  isPending: boolean;
  isError: boolean;
  refetch: () => void;
}

const LoginContext = createContext<loginContextProps>({
  isLoggedIn: false,
  goLogin: () => {},
  needLogin: () => () => {},
  isFetchedAfterMount: false,
  isPending: false,
  isError: false,
  refetch: () => {}
});

const LoginProvider = ({ children }: PropsWithChildren) => {
  const login = useLogin();
  return <LoginContext.Provider value={login}>{children}</LoginContext.Provider>;
};

export { LoginContext, LoginProvider };
