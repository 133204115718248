import { memo } from 'react';
import { useGetFirstPlanningUrl } from '@tkl-apps/ticketlink/src/hooks/planning/useGetFirstPlanningUrl';
import { useGetSpeechBubblers } from '@tkl-apps/ticketlink/src/hooks/planning/useGetSpeechBubblers';
import { useSubHeaderType } from '@tkl-packages/hooks/src/useSubHeaderType';
import { isSports } from '@tkl-packages/sdk/src/validations';
import isEmpty from 'lodash/isEmpty';
import TKLink from '../../../atoms/TKLink';

const Bubble = () => {
  const bubble = useGetSpeechBubblers();

  return (
    <span className="exhibit_badge">
      <TKLink to={bubble?.isIntegration ? `/planning-event/${bubble?.planningEventId}` : '#'} className="link">
        {bubble?.content}
      </TKLink>
    </span>
  );
};

const PlanningLi = () => {
  const { data: firstPlanningUrl } = useGetFirstPlanningUrl(!isSports());
  const { includesUrlPath } = useSubHeaderType();

  const bubble = useGetSpeechBubblers();

  return (
    <li className="header_gnb_item">
      <div className="header_badge_wrap">
        <TKLink
          to={firstPlanningUrl || '/planning-event'}
          className="header_gnb_link"
          aria-current={includesUrlPath('/planning-event')}
        >
          기획전
        </TKLink>
        {!isEmpty(bubble) && <Bubble />}
      </div>
    </li>
  );
};

export default memo(PlanningLi);
