import { forwardRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductContext } from '@tkl-apps/ticketlink/src/components/Context/Product';
import { ReserveButton } from '@tkl-apps/ticketlink/src/components/Product';
import { moveToAdult } from '@tkl-apps/ticketlink/src/utils/product';
import { SALE_STATE } from '@tkl-packages/constants/product';
import { GlobalView } from '@tkl-packages/sdk/src/utils';
import dayjs from 'dayjs';

interface props {
  globalWebUseYn: boolean;
}

const ReserveNotAvailable = forwardRef(({ globalWebUseYn }: props, ref: any) => {
  const isGlobal = GlobalView.isGlobal();
  const { t } = useTranslation();
  const {
    isShowAuthFan,
    fanclubName,
    onFanClubCertify,
    saleStatus,
    onGlobalReserve,
    isAuthReinforced,
    isShowAuthAdult,
    isNeedAuthAdult,
    adultAuthExpireDate
  } = useContext(ProductContext);

  return (
    <section className="common_section section_product_reserve">
      <h2 className="blind">{t('상품 예매')}</h2>
      <div className="product_detail_reserve" style={{ display: 'flex' }}>
        <p className="product_reserve_notice">
          {saleStatus === SALE_STATE.BEFORE ? t('예매 오픈 전 입니다.') : t('예매 마감 되었습니다.')}
        </p>
      </div>
      <div className="product_reserve_util" ref={ref}>
        <div className="product_reserve_btnarea">
          <>
            {isShowAuthAdult &&
              (isNeedAuthAdult ? (
                <a className="common_btn btn_ghost btn_xlarge" onClick={moveToAdult}>
                  {t('성인인증')}
                </a>
              ) : (
                <a className="common_btn btn_ghost btn_xlarge" aria-disabled="true">
                  <span>
                    {t('성인인증 완료')}
                    <br />
                    {t('만료일자')}: {dayjs(adultAuthExpireDate).tz().format('YYYY.MM.DD')}
                  </span>
                </a>
              ))}
            {isShowAuthFan && (
              <a className="common_btn btn_ghost btn_xlarge" onClick={onFanClubCertify}>
                {fanclubName} {t('인증')}
              </a>
            )}
            {(isShowAuthAdult || isShowAuthFan) && (
              <span className="product_reserve_arrow">
                <span className="common_icon icon_arrow_right ty_sm"></span>
              </span>
            )}
          </>

          {!isGlobal && globalWebUseYn && (
            <a href="#" className="common_btn btn_ghost btn_xlarge" onClick={onGlobalReserve}>
              {t('GLOBAL BOOKING')}
            </a>
          )}
          <ReserveButton
            isAuthReinforced={isAuthReinforced}
            saleStatus={saleStatus}
            className="btn_xlarge"
            onReserve={() => {}}
          />
        </div>
      </div>
    </section>
  );
});
ReserveNotAvailable.displayName = 'ReserveNotAvailable';

export default ReserveNotAvailable;
