import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { footerVisibleAtom } from '@tkl-apps/ticketlink/src/stores/footerStore/footerStore';
import TKLink from '@tkl-packages/components/src/atoms/TKLink';
import useFooterAnchor from '@tkl-packages/hooks/src/useFooterAnchor';
import { GlobalView } from '@tkl-packages/sdk/src/utils';
import { isGlobal } from '@tkl-packages/sdk/src/validations';
import { useSetAtom } from 'jotai';
import BottomPopup from './BottomPopup';
import FloatingButtons from './FloatingButtons';
import FloatingButtonsGlobal from './FloatingButtons.global';

interface FooterProps {
  useWrapUrl?: boolean;
}

/**
 * 해당 컴포넌트는 JSP Page에서 라이브러리로 재사용합니다.
 * - PC 버전 ( ko, global )
 */
const Footer = ({ useWrapUrl = false }: FooterProps) => {
  const { t } = useTranslation();
  const globalLocale = GlobalView.getGlobalLocale();
  const footerRef = useRef<HTMLElement>(null);
  const isServiceGlobal = isGlobal();
  const setIsFooterVisible = useSetAtom(footerVisibleAtom);

  const {
    moveCompany,
    movePersonalInformation,
    moveYouth,
    movePolicyUsage,
    moveHelp,
    movePartner,
    moveAd,
    moveBusinessInformation
  } = useFooterAnchor({
    useWrapUrl
  });

  useEffect(() => {
    const footerElement = footerRef.current;

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        setIsFooterVisible(entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      threshold: 0
    });

    if (footerElement) {
      observer.observe(footerElement);
    }

    return () => {
      if (footerElement) {
        observer.unobserve(footerElement);
      }
    };
  }, [setIsFooterVisible]);

  return (
    <>
      {isServiceGlobal ? (
        <>
          <BottomPopup />
          <FloatingButtonsGlobal footerRef={footerRef} />
        </>
      ) : (
        <FloatingButtons footerRef={footerRef} />
      )}

      <footer className="common_footer" ref={footerRef}>
        <div className="footer_menu">
          <ul className="footer_menu_list">
            {!globalLocale && (
              <li className="footer_menu_item">
                <TKLink to={moveCompany} className="footer_menu_link" useNative={true}>
                  {t('회사소개')}
                </TKLink>
              </li>
            )}
            <li className="footer_menu_item">
              <TKLink to={movePersonalInformation} className="footer_menu_link" useNative={useWrapUrl}>
                <span className="text_bold">{t('개인정보 처리방침')}</span>
              </TKLink>
            </li>
            {!globalLocale && (
              <li className="footer_menu_item">
                <TKLink to={moveYouth} className="footer_menu_link" useNative={useWrapUrl}>
                  {t('청소년 보호정책')}
                </TKLink>
              </li>
            )}

            <li className="footer_menu_item">
              <TKLink to={movePolicyUsage} className="footer_menu_link" useNative={useWrapUrl}>
                {t('이용약관')}
              </TKLink>
            </li>
            <li className="footer_menu_item">
              <TKLink to={moveHelp} className="footer_menu_link" useNative={true}>
                {t('고객센터')}
              </TKLink>
            </li>

            {!globalLocale && (
              <li className="footer_menu_item">
                <TKLink to={movePartner} className="footer_menu_link" useNative={useWrapUrl}>
                  {t('티켓판매안내')}
                </TKLink>
              </li>
            )}
            {!globalLocale && (
              <li className="footer_menu_item">
                <TKLink to={moveAd} className="footer_menu_link" useNative={useWrapUrl}>
                  {t('광고안내')}
                </TKLink>
              </li>
            )}
          </ul>
        </div>
        <div className="footer_inner">
          <FooterLocaleContent moveBusinessInformation={moveBusinessInformation} />
          <span className="footer_copyright">Copyright © NHN LINK Corporation. All rights reserved.</span>
          <div className="footer_etc">
            <ul className="footer_sns">
              <li className="footer_sns_item">
                <a href="https://blog.naver.com/t_link" target="_blank" className="footer_sns_link" rel="noreferrer">
                  <span className="common_icon icon_naverblog_gray">
                    <span className="blind">{t('네이버블로그')}</span>
                  </span>
                </a>
              </li>
              <li className="footer_sns_item">
                <a
                  href="https://www.youtube.com/@ticketlink012"
                  target="_blank"
                  className="footer_sns_link"
                  rel="noreferrer"
                >
                  <span className="common_icon icon_youtube_gray">
                    <span className="blind">{t('유튜브')}</span>
                  </span>
                </a>
              </li>
              <li className="footer_sns_item">
                <a
                  href="https://www.facebook.com/ticketlink"
                  target="_blank"
                  className="footer_sns_link"
                  rel="noreferrer"
                >
                  <span className="common_icon icon_facebook_gray">
                    <span className="blind">{t('페이스북')}</span>
                  </span>
                </a>
              </li>
              <li className="footer_sns_item">
                <a
                  href="https://www.instagram.com/ticketlink.co.kr/"
                  target="_blank"
                  className="footer_sns_link"
                  rel="noreferrer"
                >
                  <span className="common_icon icon_instagram_gray">
                    <span className="blind">{t('인스타그램')}</span>
                  </span>
                </a>
              </li>
              <li className="footer_sns_item">
                <a
                  href="https://twitter.com/nhn_ticketlink"
                  target="_blank"
                  className="footer_sns_link "
                  rel="noreferrer"
                >
                  <span className="common_icon icon_twitter_gray">
                    <span className="blind">{t('트위터')}</span>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

const FooterLocaleContent = (props: { moveBusinessInformation: string }) => {
  const locale = GlobalView.getGlobalLocale();

  switch (locale) {
    case false:
      return <FooterLocaleKo />;
    case 'ja':
      return <FooterLocaleJa />;
    case 'zh':
      return <FooterLocaleZh />;
    case 'en':
    default:
      return <FooterLocaleEn />;
  }

  function FooterLocaleKo() {
    return (
      <>
        <span className="footer_corp_title">엔에이치엔링크 주식회사</span>
        <address className="footer_address">
          <p>
            <span className="footer_address_item">주소 : 06043 서울특별시 강남구 강남대로 586, 제이빌딩</span>
            <span className="footer_address_item">대표이사: 왕문주</span>
            <span className="footer_address_item">사업자등록번호: 144-81-25090</span>
          </p>
          <p>
            <span className="footer_address_item">1588-7890</span>
            <span className="footer_address_item">membership@nhnlink.co.kr</span>
            <span className="footer_address_item">통신판매업 신고번호: 제 2023-서울강남-04352호</span>
            <span className="footer_address_item">
              <a href={props.moveBusinessInformation} target="_blank" className="btn_hyperlink" rel="noreferrer">
                사업자정보확인
              </a>
            </span>
            <span className="footer_address_item">개인정보보호 책임자: 서상훈</span>
          </p>
        </address>
      </>
    );
  }

  function FooterLocaleEn() {
    return (
      <>
        <span className="footer_corp_title">NHN LINK</span>
        <address className="footer_address">
          <p>
            <span className="footer_address_item">
              Adress : J Building, 586, Gangnam-daero, Gangnam-gu, Seoul, Republic of Korea, 06043
            </span>
            <span className="footer_address_item">Chief Executive Officer : Wang Moon Ju</span>
            <span className="footer_address_item">Business Registration Number : 144-81-25090</span>
          </p>
          <p>
            <span className="footer_address_item">1644-3850 (only english)</span>
            <span className="footer_address_item">membership@nhnlink.co.kr</span>
            <span className="footer_address_item">
              Telecommunication sale business registration number : 2023-Seoul Gangnam-04352
            </span>
            <span className="footer_address_item">Business Info Verification : Seo Sang Hoon</span>
          </p>
        </address>
      </>
    );
  }
  function FooterLocaleJa() {
    return (
      <>
        <span className="footer_corp_title">NHN LINK</span>
        <address className="footer_address">
          <p>
            <span className="footer_address_item">住所 : 06043 ソウル特別市 江南区 江南大路 586, Jビルディング</span>
            <span className="footer_address_item">代表取締役 : 王文柱</span>
            <span className="footer_address_item">事業者登録番号 : 144-81-25090</span>
          </p>
          <p>
            <span className="footer_address_item">1644-3850 (英語による対応のみ可能です)</span>
            <span className="footer_address_item">membership@nhnlink.co.kr</span>
            <span className="footer_address_item">通信販売業申告番号 : 第2023-ソウル江南-04352号</span>
            <span className="footer_address_item">個人情報保護責任者 : 徐祥熏</span>
          </p>
        </address>
      </>
    );
  }
  function FooterLocaleZh() {
    return (
      <>
        <span className="footer_corp_title">NHN LINK</span>
        <address className="footer_address">
          <p>
            <span className="footer_address_item">地址 : 06043 尔特别市江南区江南大路586, J大厦</span>
            <span className="footer_address_item">代表理事 : 王文柱</span>
            <span className="footer_address_item">营业执照编号 : 144-81-25090</span>
          </p>
          <p>
            <span className="footer_address_item">1644-3850 (只提供英语咨询)</span>
            <span className="footer_address_item">membership@nhnlink.co.kr</span>
            <span className="footer_address_item">通信贩卖业申请编号 : 第2023-首尔江南-04352号</span>
            <span className="footer_address_item">隐私保护负责人 : 徐祥熏</span>
          </p>
        </address>
      </>
    );
  }
};
