const LOCALE = {
  KO: 'ko',
  EN: 'en',
  JA: 'ja',
  ZH: 'zh'
} as const;

const DEFAULT_LOCALE = LOCALE.KO;

const LANGUAGES = [
  {
    code: LOCALE.KO,
    name: 'KOR',
    localizedName: '한국어',
    iconClassName: 'kor'
  },
  {
    code: LOCALE.EN,
    name: 'ENG',
    localizedName: 'English',
    iconClassName: 'eng'
  },
  {
    code: LOCALE.JA,
    name: 'JPN',
    localizedName: '日本語',
    iconClassName: 'jpn'
  },
  {
    code: LOCALE.ZH,
    name: 'CHN',
    localizedName: '中文',
    iconClassName: 'chn'
  }
] as const;

type ServiceLocale = (typeof LOCALE)[keyof typeof LOCALE];
type GlobalServiceLocale = Exclude<ServiceLocale, typeof DEFAULT_LOCALE>;

export { LOCALE, DEFAULT_LOCALE, LANGUAGES, ServiceLocale, GlobalServiceLocale };
