import { Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeaderGlobal from '../../components/HTMLHeader/NormalHeader.global';
// import { CategoryGlobalMobile } from '../../pages/global/mobile';

const CategoryRouteGlobal = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeaderGlobal>
            <Channel
              // TODO:II:GLOBAL_MOBILE
              mobile={undefined}
              // mobile={<CategoryGlobalMobile />}
            />
          </NormalHeaderGlobal>
        }
      />
    </Routes>
  );
};

export default CategoryRouteGlobal;
