import { SPORTS_TAB } from '@tkl-packages/constants/sports';
import { SportTabTypeCode } from '@tkl-packages/models';

export const setTabName = (code: SportTabTypeCode, tabName: string) => {
  switch (code) {
    case SPORTS_TAB.INFO:
      return tabName ?? '구단안내';
    case SPORTS_TAB.PRICE:
      return tabName ?? '요금/할인카드';
    case SPORTS_TAB.SEAT:
      return tabName ?? '좌석도';
  }
};
