import { useTranslation } from 'react-i18next';

const ScrollTopButton = () => {
  const { t } = useTranslation();
  return (
    <button type="button" className="toolbar_top" onClick={() => window.scrollTo({ top: 0 })}>
      <span className="blind">{t('맨 위로 이동하기')}</span>
    </button>
  );
};

export default ScrollTopButton;
