import { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { LoginProvider } from '@tkl-apps/ticketlink/src/components/Context/Login';
import { ModalProvider } from '@tkl-apps/ticketlink/src/components/Context/Modal';
import ReactQueryProvider from '@tkl-apps/ticketlink/src/components/Context/ReactQuery';
import DefaultRoute from '@tkl-apps/ticketlink/src/routes/Route';
import GlobalRoute from '@tkl-apps/ticketlink/src/routes/Route.global';
import i18n from '@tkl-packages/i18n';
import { initDayjs } from '@tkl-packages/sdk/src/utils/date'; // import './utils/datadog';
import { createRoot } from 'react-dom/client';
// import './styles/app.scss';
// import "@tkl-apps/drtour";

initDayjs();
const root = createRoot(document.querySelector('#app')!);

root.render(
  <ReactQueryProvider isShowDevTools={false}>
    <BrowserRouter>
      <StrictMode>
        <LoginProvider>
          <ModalProvider>
            <HelmetProvider>
              <I18nextProvider i18n={i18n}>
                <Routes>
                  <Route path="/global/*" element={<GlobalRoute />} />
                  <Route path="/*" element={<DefaultRoute />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </I18nextProvider>
            </HelmetProvider>
          </ModalProvider>
        </LoginProvider>
      </StrictMode>
    </BrowserRouter>
  </ReactQueryProvider>
);
