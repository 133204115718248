import { useState, useEffect } from 'react';

const usePortal = (tag: string) => {
  const [portalContainer, setPortalContainer] = useState<Element | null>(null);

  useEffect(() => {
    const element = document.querySelector(tag);
    if (element) {
      setPortalContainer(element);
    }
  }, [tag]);

  return { portalContainer };
};

export default usePortal;
