import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router';
import usePortal from '@tkl-packages/hooks/src/usePortal';
import { useSubHeaderType } from '@tkl-packages/hooks/src/useSubHeaderType';
import { GnbCategory } from '@tkl-packages/models';
import { GlobalView } from '@tkl-packages/sdk/src/utils';
import { CategoryListLayer } from '../CategoryListLayer';
import { makeCategoryPath, seasonClassName } from '../utils';

type CategoryListLayerItemProps = {
  category: GnbCategory;
};
export const CategoryListLayerItem = (props: CategoryListLayerItemProps) => {
  const [activeLayer, setActiveLayer] = useState(false);

  const { portalContainer } = usePortal('header');

  const navigate = useNavigate();

  const path = makeCategoryPath(props.category.upperCategoryId, props.category.categoryId);

  const { includesUrlPath } = useSubHeaderType();
  const currentCategoryPage =
    includesUrlPath(path) ||
    // 오프 시즌 처리
    (props.category.categoryNameEng.length > 0 && includesUrlPath(props.category.categoryNameEng));

  const handleLink = (url: string | undefined) => {
    if (!url) return;
    return url.startsWith('http') ? (window.location.href = url) : navigate(GlobalView.applyPath(url));
  };

  const onEventHandler = () => {
    const link = GlobalView.applyGlobalEngField(
      props.category.globalEventPageLinkInfo?.pcCategoryLinkInfo?.url,
      props.category.eventPageLinkInfo?.pcCategoryLinkInfo?.url
    );
    handleLink(link);
  };

  const isOffSeason = props.category.seasonSettingType === 'OFF_SEASON';

  return (
    <>
      <span
        className={`header_gnb_link ${seasonClassName(props.category)}`}
        aria-current={currentCategoryPage}
        onClick={() => {
          if (props.category.gnbExposureTypeCode === 'EVENT') {
            onEventHandler();
            window.scrollTo(0, 0);
          }
          if (!GlobalView.isGlobal() && isOffSeason) {
            navigate(`/sports/${props.category.categoryNameEng}/off-season`);
            window.scrollTo(0, 0);
          }
        }}
        onMouseEnter={() => setActiveLayer(true)}
        onMouseLeave={() => setActiveLayer(false)}
      >
        {portalContainer &&
          createPortal(
            <CategoryListLayer
              setActiveLayer={setActiveLayer}
              isOffSeason={isOffSeason}
              isActive={activeLayer}
              category={props.category}
            />,
            portalContainer
          )}
        {GlobalView.applyGlobalField({
          ko: props.category.categoryNameKor,
          en: props.category.categoryNameEng,
          ja: props.category.categoryNameJa,
          zh: props.category.categoryNameZh
        })}
      </span>
    </>
  );
};
