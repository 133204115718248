import { useTranslation } from 'react-i18next';
import { ModalProps } from '@tkl-packages/models';
import useNoScroll from '../../../../hooks/src/useNoScroll';

const FullScreenModalMobile = ({ title, children, onClose }: ModalProps) => {
  useNoScroll();
  const { t } = useTranslation();
  return (
    <div className="common_popup_wrap">
      <div className="common_popup" role="dialog" aria-modal="true">
        <div className="common_popup_header">
          <h3 className="common_popup_title">{title}</h3>
          <button type="button" className="common_popup_close" onClick={onClose}>
            <span className="blind">{t('닫기')}</span>
          </button>
        </div>
        <div className="common_popup_content">{children}</div>
      </div>
    </div>
  );
};

export default FullScreenModalMobile;
