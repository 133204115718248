import { Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeaderGlobal from '../../components/HTMLHeader/NormalHeader.global';

const TicketRouteGlobal = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeaderGlobal>
            {/* TODO:II:GLOBAL_MOBILE */}
            <Channel mobile={undefined} />
            {/* <Channel mobile={<TicketGlobalMobile />} /> */}
          </NormalHeaderGlobal>
        }
      />
    </Routes>
  );
};

export default TicketRouteGlobal;
