import TKLink from '@tkl-packages/components/src/atoms/TKLink';
import { useSubHeaderProductInfo, useSubHeaderType } from '@tkl-packages/hooks/src/useSubHeaderType';
import { GnbCategory } from '@tkl-packages/models';
import { GlobalView } from '@tkl-packages/sdk/src/utils';
import { makeCategoryPath } from '../utils';

type CategoryListAnchorItemProps = {
  category: GnbCategory;
};

export const CategoryListAnchorItem = (props: CategoryListAnchorItemProps) => {
  const path = makeCategoryPath(props.category.upperCategoryId, props.category.categoryId);
  const { includesUrlPath } = useSubHeaderType();
  const { matchProductInfo } = useSubHeaderProductInfo();

  const isIncludePath = includesUrlPath(path);
  const matchedProductInfo = GlobalView.isGlobal() ? matchProductInfo(props.category.categoryId.toString()) : false;

  return (
    <TKLink
      to={path}
      className="header_gnb_link"
      aria-current={isIncludePath || matchedProductInfo}
      onClick={() => window.scrollTo(0, 0)}
    >
      {GlobalView.applyGlobalField({
        ko: props.category.categoryNameKor,
        en: props.category.categoryNameEng,
        ja: props.category.categoryNameJa,
        zh: props.category.categoryNameZh
      })}
    </TKLink>
  );
};
