import { lazy } from 'react';

const NotFound = lazy(() => import(/* webpackChunkName: "not-found" */ './Error/NotFound'));
const Advertisement = lazy(() => import(/* webpackChunkName: "advertisement" */ './Advertisement'));
const Faq = lazy(() => import(/* webpackChunkName: "faq" */ './Help/Faq'));
const FaqDetail = lazy(() => import(/* webpackChunkName: "faq-detail" */ './Help/FaqDetail'));
const Partner = lazy(() => import(/* webpackChunkName: "help-partner" */ './Help/Partner'));
const Main = lazy(() => import(/* webpackChunkName: "main" */ './Main'));
const Exhibition = lazy(() => import(/* webpackChunkName: "exhibition" */ './Product/Exhibition'));
const Local = lazy(() => import(/* webpackChunkName: "local" */ './Product/Local'));
const Performance = lazy(() => import(/* webpackChunkName: "performance" */ './Product/Performance'));
const Product = lazy(() => import(/* webpackChunkName: "product" */ './Product/Product'));
const Ranking = lazy(() => import(/* webpackChunkName: "ranking" */ './Product/Ranking'));
const SportsMain = lazy(() => import(/* webpackChunkName: "sports-main" */ './Sports/Main'));
const SportsOffSeason = lazy(() => import(/* webpackChunkName: "sports-offseason" */ './Sports/OffSeason'));
const MyDeposit = lazy(() => import(/* webpackChunkName: "document-deposit" */ './User/My/Document/Deposit'));
const MyCash = lazy(() => import(/* webpackChunkName: "document-cash" */ './User/My/Document/Cash'));
const MyCreditCard = lazy(() => import(/* webpackChunkName: "document-credit-card" */ './User/My/Document/CreditCard'));
const SportsTeam = lazy(() => import(/* webpackChunkName: "sports-offseason" */ './Sports/Team'));
const Bugs = lazy(() => import(/* webpackChunkName: "bugs" */ './Product/Bugs'));
const PrivacyPolicy = lazy(() => import(/* webpackChunkName: "privacy-policy" */ './Policy/PrivacyPolicy'));
const PrivacyFacilityPolicyUsage = lazy(
  () => import(/* webpackChunkName: "privacy-facility-policy-usage" */ './Policy/PrivacyFacilityPolicyUsage')
);

const PrivacyPolicyUsage = lazy(
  () => import(/* webpackChunkName: "privacy-policy-usage" */ './Policy/PrivacyPolicyUsage')
);

const PrivacyFacilityThirdParty = lazy(
  () => import(/* webpackChunkName: "privacy-facility-policy-usage" */ './Policy/PrivacyFacilityThirdParty')
);

const YouthPolicy = lazy(() => import(/* webpackChunkName: "youth-policy" */ './Policy/YouthPolicy'));
const UsagePolicy = lazy(() => import(/* webpackChunkName: "usage-policy" */ './Policy/UsagePolicy'));
const PromotionalPolicy = lazy(() => import(/* webpackChunkName: "promotional-policy" */ './Policy/PromotionalPolicy'));
const GiftCardPolicy = lazy(() => import(/* webpackChunkName: "gift-card-policy" */ './Policy/GiftCardPolicy'));
const MarketingPolicy = lazy(() => import(/* webpackChunkName: "marketing-policy" */ './Policy/MarketingPolicy'));
const Planning = lazy(() => import(/* webpackChunkName: "exhibition-planning" */ './Planning/Planning'));
const GuideCharge = lazy(() => import(/* webpackChunkName: "guide-charge" */ './Help/Guide/Charge'));
const GuideCancel = lazy(() => import(/* webpackChunkName: "guide-cancel" */ './Help/Guide/Cancel'));
const GuideDiscountCard = lazy(() => import(/* webpackChunkName: "guide-discount-card" */ './Help/Guide/DiscountCard'));
const GuideDiscountMethod = lazy(
  () => import(/* webpackChunkName: "guide-discount-method" */ './Help/Guide/DiscountMethod')
);
const GuideLegal = lazy(() => import(/* webpackChunkName: "guide-legal" */ './Help/Guide/Legal'));
const GuideMemberInfo = lazy(() => import(/* webpackChunkName: "guide-member-info" */ './Help/Guide/MemberInfo'));
const GuideReservation = lazy(() => import(/* webpackChunkName: "guide-reservation" */ './Help/Guide/Reservation'));
const GuideReservationChannel = lazy(
  () => import(/* webpackChunkName: "guide-reservation-channel" */ './Help/Guide/ReservationChannel')
);
const GuidePopupSmartTicket = lazy(
  () => import(/* webpackChunkName: "guide-popup-smart-ticket" */ './Help/Guide/Popup/SmartTicket')
);
const GuidePayment = lazy(() => import(/* webpackChunkName: "guide-payment" */ './Help/Guide/Payment'));
const GuideTicket = lazy(() => import(/* webpackChunkName: "guide-ticket" */ './Help/Guide/Ticket'));
const GuideWaitingReservation = lazy(
  () => import(/* webpackChunkName: "guide-waiting-reservation" */ './Help/Guide/WaitingReservation')
);

export {
  Performance,
  Exhibition,
  Local,
  Product,
  Ranking,
  MyDeposit,
  MyCash,
  MyCreditCard,
  Main,
  SportsMain,
  SportsOffSeason,
  SportsTeam,
  NotFound,
  Advertisement,
  Faq,
  FaqDetail,
  Partner,
  Bugs,
  PrivacyPolicy,
  PrivacyFacilityPolicyUsage,
  PrivacyPolicyUsage,
  YouthPolicy,
  UsagePolicy,
  PromotionalPolicy,
  GiftCardPolicy,
  MarketingPolicy,
  Planning,
  GuideCharge,
  GuideCancel,
  GuideDiscountCard,
  GuideDiscountMethod,
  GuideLegal,
  GuideMemberInfo,
  GuideReservation,
  GuideReservationChannel,
  GuidePopupSmartTicket,
  GuidePayment,
  GuideTicket,
  GuideWaitingReservation,
  PrivacyFacilityThirdParty
};
