import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { VIEW_GRADE } from '@tkl-packages/constants/product';
import { ProductDetail } from '@tkl-packages/models';
import { extractNumbers } from '@tkl-packages/sdk/src/utils';
import ProductInfoItem from './index';

interface IProps {
  isVisible?: boolean;
  product: ProductDetail;
}

const AgeOfLimit = ({ isVisible = true, product }: IProps) => {
  const { t } = useTranslation();

  const text = useMemo(() => {
    const age = extractNumbers(product.viewClassCode === VIEW_GRADE.AGE ? product.viewAgeCode : product.viewTypeCode);
    if (product.viewClassCode === VIEW_GRADE.AGE) {
      if (product.viewAgeCode.includes('OVER')) {
        return t('만<<age>>세이상', { age });
      }
      if (product.viewAgeCode.includes('FROM')) {
        return t('<<age>>세이상', { age });
      }
    }

    if (product.viewTypeCode.includes('MONTHS')) {
      return t('<<months>>개월이상', { months: age });
    }
    return t(product.viewTypeName);
  }, [product.viewAgeCode, product.viewClassCode, product.viewTypeCode, product.viewTypeName, t]);

  return <ProductInfoItem isVisible={isVisible} title={t('관람등급')} desc={text} />;
};

export default AgeOfLimit;
