import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, matchPath } from 'react-router';
import { SESSION_KEY } from '@tkl-packages/constants/user';
import useLogin from '@tkl-packages/hooks/src/useLogin';
import { GlobalView } from '@tkl-packages/sdk/src/utils';

const BottomPopup = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isPending, isLoggedIn, goLogin } = useLogin();
  const [isPopupActive, setIsPopupActive] = useState(false);
  const isPopupAllowed = useMemo(() => {
    const allowedPaths = [
      GlobalView.applyPath('/home'),
      GlobalView.applyPath('/performance/:category'),
      GlobalView.applyPath('/exhibition/:category'),
      GlobalView.applyPath('/local'),
      GlobalView.applyPath('/sports/:categoryId/:teamId'),
      GlobalView.applyPath('/product/:productId')
    ].find((paths) => matchPath(paths, pathname));

    return allowedPaths;
  }, [pathname]);

  const setPopupDisable = (disabled: boolean) => {
    const popupDisableState = disabled ? 'DISABLED' : 'ENABLED';
    window.sessionStorage.setItem(SESSION_KEY.BOTTOM_POPUP_DISABLED, popupDisableState);
  };

  const onClickClose = () => {
    setIsPopupActive(false);
    setPopupDisable(true);
  };

  const isShowPopup = !!(!isPending && !isLoggedIn && !isPopupActive && isPopupAllowed);

  useEffect(() => {
    const bottomPopupDisabled = window.sessionStorage.getItem(SESSION_KEY.BOTTOM_POPUP_DISABLED);
    const isPopupDisabled = bottomPopupDisabled === 'DISABLED';

    if (isShowPopup && !isPopupDisabled) {
      setTimeout(() => {
        setIsPopupActive(true);
      }, 1000);
    }
  }, [isShowPopup]);

  return (
    <div className={`bottom_popup ${isPopupActive ? 'open' : ''}`}>
      <div className="common_inner">
        <a className="text" onClick={goLogin}>
          {t('티켓 구매를 위해 로그인해 주세요.')}
        </a>
        <button type="button" className="bottom_popup_close" onClick={onClickClose}>
          <span className="blind">{t('닫기')}</span>
        </button>
      </div>
    </div>
  );
};

export default BottomPopup;
