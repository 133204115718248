import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import useLogin from '@tkl-packages/hooks/src/useLogin';
import { getAdultAuthStatusApi } from '@tkl-packages/services/src/mapi';

export const useAdultAuthStatus = () => {
  const { isLoggedIn } = useLogin();
  const {
    isLoading,
    data: adultAuth,
    refetch: refetchAdultAuth
  } = useQuery({
    enabled: isLoggedIn,
    queryKey: [QUERY_KEY.ADULT_AUTH],
    queryFn: () => getAdultAuthStatusApi().then((res) => res.data),
    select: (auth) => {
      return auth.adultAuthYn === 'Y';
    }
  });

  return { isLoading, isAdultAuth: Boolean(adultAuth), refetchAdultAuth };
};
