import { CATEGORY } from '@tkl-packages/constants/category';
import { GnbCategory } from '@tkl-packages/models';

import { CategoryListAnchorItem } from './CategoryListAnchorItem';
import { CategoryListLayerItem } from './CategoryListLayerItem';

type CategoryListItemProps = {
  category: GnbCategory;
};
export const CategoryListItem = (props: CategoryListItemProps) => {
  switch (props.category.upperCategoryId) {
    case CATEGORY.SPORTS:
      return <CategoryListLayerItem category={props.category} />;
    default:
      return <CategoryListAnchorItem category={props.category} />;
  }
};
