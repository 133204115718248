import { ScheduleMatch } from '@tkl-packages/models';
import { GlobalView } from '@tkl-packages/sdk/src/utils';

export const formatWeekDay = (nameOfDay: string): string => {
  const locale = GlobalView.getGlobalLocale();

  if (locale === 'en') {
    return nameOfDay.toUpperCase().substring(0, 3);
  }
  if (locale === 'zh') {
    return nameOfDay.substring(2, 3);
  }

  return nameOfDay.charAt(0);
};

export type CalendarDate = {
  date: number | null;
  match?: ScheduleMatch;
  isCurrentMonth: boolean;
  isAnniversary?: boolean;
  anniversaryName?: string;
};

export const DAYS_OF_WEEK = [
  { name: '월요일', className: 'mon' },
  { name: '화요일', className: 'tue' },
  { name: '수요일', className: 'wed' },
  { name: '목요일', className: 'thu' },
  { name: '금요일', className: 'fri' },
  { name: '토요일', className: 'sat' },
  { name: '일요일', className: 'sun' }
] as const;

export const STATUS_MAP = {
  ON_SALE: '예매하기',
  BEFORE: '오픈예정',
  END: '판매종료'
} as const;

export const STATUS_MAP_ENG = {
  ON_SALE: 'Book Now',
  BEFORE: 'Coming Soon',
  END: 'Sale Ended'
} as const;

export const getCalendarDates = (currentDate: Date, schedules: ScheduleMatch[]) => {
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const firstDateOfMonth = new Date(year, month, 1);
  const lastDateOfMonth = new Date(year, month + 1, 0);
  const firstDay = (firstDateOfMonth.getDay() + 6) % 7;
  const lastDate = lastDateOfMonth.getDate();
  const dates: CalendarDate[] = [];

  for (let i = 0; i < firstDay; i++) {
    dates.push({
      date: null,
      isCurrentMonth: false
    });
  }

  for (let date = 1; date <= lastDate; date++) {
    const matchForDate = schedules.find((schedule) => {
      const scheduleDate = new Date(schedule.scheduleDate);
      return (
        scheduleDate.getDate() === date && scheduleDate.getMonth() === month && scheduleDate.getFullYear() === year
      );
    });

    dates.push({
      date,
      match: matchForDate,
      isCurrentMonth: true
    });
  }

  const totalDays = firstDay + lastDate;
  const minimumWeeksNeeded = Math.ceil(totalDays / 7);

  const totalCells = minimumWeeksNeeded * 7;

  const remainingDays = totalCells - dates.length;
  for (let i = 0; i < remainingDays; i++) {
    dates.push({
      date: null,
      isCurrentMonth: false
    });
  }

  return dates;
};
