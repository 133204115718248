import { isGlobal } from '../validations';

const getGlobalChannel = () => {
  if (window.location.pathname.startsWith('/global/en')) {
    return 'en';
  }

  if (window.location.pathname.startsWith('/global/ja')) {
    return 'ja';
  }

  if (window.location.pathname.startsWith('/global/zh')) {
    return 'zh';
  }

  if (isGlobal()) return 'en';

  return 'ko';
};

const isProductionHost = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const { hostname } = location;
  const phase = hostname;
  const targets = ['local', 'new', 'alpha', 'beta' /* 'stage' */];
  if (targets.some((target) => phase.includes(target))) {
    return false;
  }
  return true;
};
const getChannelServiceCode = () => {
  const isLocal =
    location.hostname.includes('local.') ||
    location.hostname.includes('local-m.') ||
    location.hostname.includes('localhost');
  const isAlpha = location.hostname.includes('alpha');
  const isBeta = location.hostname.includes('beta');
  const isAlpha2 = location.hostname.includes('alpha2');
  const isBeta2 = location.hostname.includes('beta2');
  const isStage = location.hostname.includes('stage');

  if (isLocal) {
    return 'Local';
  } else if (isAlpha2) {
    return 'Alpha';
  } else if (isAlpha) {
    return 'Alpha';
  } else if (isBeta2) {
    return 'Beta';
  } else if (isBeta) {
    return 'Beta';
  } else if (isStage) {
    return 'Stage';
  }

  return 'Production';
};

export { getGlobalChannel, isProductionHost, getChannelServiceCode };
