import { FC, PropsWithChildren } from 'react';
import useConditionalCSS from '@tkl-apps/ticketlink/src/hooks/header/useConditionalCSS';
import { GoogleAnalytics } from '@tkl-packages/components/src/ticketlink/GA';
import { OgTag } from '@tkl-packages/components/src/ticketlink/OgTag';
import { GlobalView } from '@tkl-packages/sdk/src/utils';
import { isPc } from '@tkl-packages/sdk/src/validations';

const SportsHeader: FC<PropsWithChildren> = ({ children }) => {
  // TODO:II:GLOBAL_MOBILE 모바일 출시 전까지
  const isActive = GlobalView.isGlobal() ? true : isPc();
  useConditionalCSS({
    href: `${process.env.CSS_URL}/popup.css`,
    isActive: isActive
  });
  useConditionalCSS({
    href: `${process.env.CSS_URL}/sports.css`,
    isActive: isActive
  });

  return (
    <>
      <OgTag />
      <GoogleAnalytics />
      {children}
    </>
  );
};

export default SportsHeader;
