import { RefObject, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useScroll from '@tkl-packages/hooks/src/useScroll';

interface props {
  footerRef: RefObject<HTMLElement>;
}

const FloatingButtons = ({ footerRef }: props) => {
  const { t } = useTranslation();
  const [isStuck, setIsStuck] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const onScroll = useCallback(() => {
    const scrollHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
    const scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
    const scrollBottom = scrollHeight - window.innerHeight - scrollTop;
    let nowIsActive = false;
    let nowIsStuck = false;

    if (scrollTop !== 0) {
      nowIsActive = true;
    }

    if (footerRef.current && scrollBottom - footerRef.current.clientHeight + 80 < 20) {
      nowIsStuck = true;
    }

    if (nowIsActive !== isActive) {
      setIsActive(nowIsActive);
    }

    if (nowIsStuck !== isStuck) {
      setIsStuck(nowIsStuck);
    }

    scrollTop;
  }, [footerRef, isActive, isStuck]);

  useScroll(onScroll);

  return (
    <button
      type="button"
      className={`btn_move_top ${isActive ? 'is-active' : ''} ${isStuck ? 'is-stuck' : ''}`}
      onClick={() => window.scrollTo({ top: 0 })}
    >
      <span className="blind">{t('맨 위로 이동하기')}</span>
    </button>
  );
};

export default FloatingButtons;
