import { lazy } from 'react';

const NotFoundGlobal = lazy(() => import(/* webpackChunkName: "not-found" */ './Error/NotFound'));

const MainGlobal = lazy(() => import(/* webpackChunkName: "main" */ './Main.global'));

const LocalGlobal = lazy(() => import(/* webpackChunkName: "local" */ './Product/Local'));
const ProductGlobal = lazy(() => import(/* webpackChunkName: "product" */ './Product/Product'));
const ExhibitionGlobal = lazy(() => import(/* webpackChunkName: "performance" */ './Product/Exhibition'));
const PerformanceGlobal = lazy(() => import(/* webpackChunkName: "performance" */ './Product/Performance'));

const SportsTeamGlobal = lazy(() => import(/* webpackChunkName: "usage-policy" */ './Sports/Team'));

const PrivacyPolicyGlobal = lazy(() => import(/* webpackChunkName: "privacy-policy" */ './Policy/PrivacyPolicy'));
const UsagePolicyGlobal = lazy(() => import(/* webpackChunkName: "usage-policy" */ './Policy/UsagePolicy'));

// 기존 모바일 코드 참고.
// const Advertisement = lazy(() => import(/* webpackChunkName: "advertisement" */ './Advertisement'));
// const Faq = lazy(() => import(/* webpackChunkName: "faq" */ './Help/Faq'));
// const FaqDetail = lazy(() => import(/* webpackChunkName: "faq-detail" */ './Help/FaqDetail'));
// const Partner = lazy(() => import(/* webpackChunkName: "help-partner" */ './Help/Partner'));
// const My = lazy(() => import(/* webpackChunkName: "my" */ './User/My'));

const FaqGlobal = lazy(() => import(/* webpackChunkName: "faq" */ './Help/Faq'));
const FaqDetailGlobal = lazy(() => import(/* webpackChunkName: "faq-detail" */ './Help/FaqDetail'));
const GuideReservationGlobal = lazy(() => import(/* webpackChunkName: "guide-reservation" */ './Help/Reservation'));
const GuideTicketGlobal = lazy(() => import(/* webpackChunkName: "guide-ticket" */ './Help/Guide/Ticket'));
const GuideSignUpGlobal = lazy(() => import(/* webpackChunkName: "guide-signup" */ './Help/Guide/Signup'));

const PlanningGlobal = lazy(() => import(/* webpackChunkName: "exhibition-planning" */ './Planning/Planning'));

export {
  NotFoundGlobal,
  MainGlobal,
  ProductGlobal,
  PrivacyPolicyGlobal,
  UsagePolicyGlobal,
  SportsTeamGlobal,
  ExhibitionGlobal,
  PerformanceGlobal,
  LocalGlobal,
  FaqDetailGlobal,
  FaqGlobal,
  GuideReservationGlobal,
  GuideTicketGlobal,
  PlanningGlobal,
  GuideSignUpGlobal
};
