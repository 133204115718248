const COOKIE_OPTION = {
  domain: '.ticketlink.co.kr'
} as const;

const LOCAL_STORAGE_KEY = {
  FRAUDULENT_RESERVATION_NOTICE_SHOW: 'FRAUD_RESERVATION_NOTICE_SHOW'
};

const SESSION_KEY = {
  MAIN_FLASH_SHOW: 'MAIN_FLASH_SHOW',
  HEADER_TYPE: 'HEADER_TYPE',
  SUB_HEADER_TYPE: 'SUB_HEADER_TYPE',
  BOTTOM_POPUP_DISABLED: 'BOTTOM_POPUP_DISABLED',
  GLOBAL_GNB_SWIPER_INDEX: 'GLOBAL_GNB_SWIPER_INDEX'
} as const;

const VISIBLE_VALUE = {
  SHOW: 'Y',
  HIDE: 'N'
} as const;

const HOME_TYPE = {
  EXHIBIT: 'EXHIBIT',
  SPORTS: 'SPORTS'
} as const;

const HEADER_TYPE = {
  SHOW: 'SHOW',
  SPORTS: 'SPORTS',
  DRTOUR: 'DRTOUR'
} as const;

const SUB_HEADER_TYPE = {
  ESPORTS: 'ESPORTS',
  CONCERT: 'CONCERT',
  EXHIBITION: 'EXHIBITION',
  LOCAL: 'LOCAL',
  EVENT_COUPON: 'EVENT_COUPON'
} as const;

const NOTIFICATION_READ_STATUS = {
  READ: 'READ',
  NOT_READ: 'NOT_READ'
} as const;

const APP_PUSH_AGREEMENT_TYPE = {
  NOTIFICATION: 'notificationAgreement',
  AD: 'adAgreement'
} as const;

const CERTIFIED_DEVICE_STATUS = {
  DEVICE_ACCORD: 'DEVICE_ACCORD',
  DEVICE_DISCORD: 'DEVICE_DISCORD',
  DEVICE_UNCERTIFIED: 'DEVICE_UNCERTIFIED'
} as const;

export {
  COOKIE_OPTION,
  LOCAL_STORAGE_KEY,
  SESSION_KEY,
  VISIBLE_VALUE,
  HOME_TYPE,
  HEADER_TYPE,
  SUB_HEADER_TYPE,
  NOTIFICATION_READ_STATUS,
  APP_PUSH_AGREEMENT_TYPE,
  CERTIFIED_DEVICE_STATUS
};
