import { GnbSubBanner } from '@tkl-packages/models';
import TKLink from '../../../atoms/TKLink';

export const CategoryListLayerBanner = ({ banners }: { banners: GnbSubBanner[] }) => {
  return (
    <ul className="lnb_banner">
      {banners.map((banner) => {
        return (
          <li key={banner.bannerId} className="lnb_banner_item">
            <TKLink
              to={banner.bannerPcWebLinkUrl}
              target={banner.bannerPcWebTarget === 'NEW' ? '_blank' : '_self'}
              className="lnb_banner_link"
            >
              <img src={banner.bannerPcImgPath} className="lnb_banner_img" alt="" />
            </TKLink>
          </li>
        );
      })}
    </ul>
  );
};
