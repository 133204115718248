import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOutsideClick from '@tkl-packages/hooks/src/useOutsideClick';
import { GlobalView } from '@tkl-packages/sdk/src/utils';
import AgeOfLimit from './AgeOfLimit';

interface IProps {
  isVisible?: boolean;
  title: string;
  desc?: string | React.ReactElement;
  isPopupActive?: boolean;
}

const ProductInfoItem = ({ isVisible = true, title, desc, isPopupActive }: IProps) => {
  const { t } = useTranslation();

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const onClose = () => {
    setIsPopupOpen(false);
  };

  const { ref: popupRef } = useOutsideClick(() => {
    onClose();
  });

  if (!isVisible) {
    return null;
  }
  return (
    <li className="product_info_item">
      <span className="product_info_title">{title}</span>
      <div className="product_info_desc">
        {desc}
        {isPopupActive && GlobalView.isGlobal() && (
          <div className="tooltip_btn_wrap">
            <button
              onClick={() => setIsPopupOpen((prevState) => !prevState)}
              type="button"
              className="btn_tooltip"
              aria-expanded={isPopupOpen}
            >
              <span className="common_icon icon_info ty_xs"></span>
              <span className="blind">{t('기간안내')}</span>
            </button>
            {isPopupOpen && (
              <div ref={popupRef} className="common_tooltip_wrap">
                <div className="common_tooltip" role="dialog">
                  <div className="common_tooltip_content">
                    <strong className="common_tooltip_title">{t('기간안내')}</strong>
                    <p className="common_tooltip_text">
                      {t('모든 일정은 현지 시간과 날짜를 기준으로 합니다. (KST, GMT +9:00)')}
                    </p>
                  </div>
                  <button onClick={onClose} type="button" className="common_tooltip_close">
                    <span className="blind">{t('닫기')}</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </li>
  );
};

ProductInfoItem.AgeOfLimit = AgeOfLimit;

export default ProductInfoItem;
