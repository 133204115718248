import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { ScheduleMatch, TabInfoPayload, TabInfoUI, Team } from '@tkl-packages/models';
import { Response } from '@tkl-packages/sdk/src/axios';
import {
  getSchedulesApi,
  getSportsTabInfoDetail,
  getTeamApi,
  getTeamNoticeApi,
  getTeamsApi,
  getTeamTopNotice
} from '@tkl-packages/services/src/mapi';
import dayjs from 'dayjs';

/** 해당 종목의 모든 팀을 가져오는 훅 */
export const useSportsTeams = (categoryId: string) => {
  const { data: teams } = useQuery<Response<Team[]>, Error, Team[]>({
    queryKey: [QUERY_KEY.TEAMS, categoryId],
    queryFn: () => getTeamsApi(categoryId),
    select: (data) => data.data,
    enabled: !!categoryId && categoryId !== '',
    // TODO:II 테스트 후 변경예정_QA 이후 적절한 staleTime 설정 예정
    staleTime: 10000,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  return {
    teams
  };
};

/** 해당 팀의 정보를 가져오는 훅 */
export const useSportsTeam = (teamId: string) => {
  const { data: team } = useQuery<Response<Team>, Error, Team>({
    queryKey: [QUERY_KEY.TEAM_DETAIL, teamId],
    queryFn: () => getTeamApi(teamId || ''),
    enabled: !!teamId && teamId !== '',
    select: (data) => data.data,
    // TODO:II 테스트 후 변경예정_QA 이후 적절한 staleTime 설정 예정
    staleTime: 10000,
    placeholderData: (previousData) => previousData
  });

  return {
    team
  };
};

export const useSportsSchedule = (categoryId: string, teamId: string) => {
  const startDate = dayjs().tz().format('YYYYMMDD');
  const endDate = dayjs().add(3, 'month').tz().format('YYYYMMDD');

  const { data: schedulesData } = useQuery<
    Response<{ schedules: ScheduleMatch[] }>,
    Error,
    { schedules: ScheduleMatch[] }
  >({
    queryKey: [QUERY_KEY.MATCH_LIST, categoryId, teamId],
    queryFn: () => getSchedulesApi({ categoryId, teamId, startDate, endDate }),
    select: (data) => data.data,
    enabled: categoryId !== '' && teamId !== ''
  });

  return {
    schedulesData
  };
};

export const useSportsNotice = (teamId: string, page: number, itemPerPage = 10) => {
  const { data: teamNoticeData } = useQuery({
    queryKey: [QUERY_KEY.TEAM_NOTICES, teamId, page, itemPerPage],
    queryFn: () =>
      getTeamNoticeApi({
        teamId,
        page,
        itemPerPage
      }),
    enabled: teamId !== '',
    // TODO:II 테스트 후 변경예정_QA 이후 적절한 staleTime 설정 예정
    staleTime: Infinity,
    select: (data) => data.data
  });

  return {
    teamNoticeData
  };
};

export const useSportsTopNotice = (teamId: string) => {
  const { data: teamTopNotice } = useQuery({
    queryKey: [QUERY_KEY.TEAM_TOP_NOTICE, teamId],
    queryFn: () => getTeamTopNotice(teamId),
    enabled: teamId !== '',
    // TODO:II 테스트 후 변경예정_QA 이후 적절한 staleTime 설정 예정
    staleTime: Infinity,
    select: (data) => data.data
  });

  return {
    teamTopNotice
  };
};

export const useSportsTabInfoDetail = (tabInfo: TabInfoUI) => {
  const tabInfoPayload: TabInfoPayload = {
    typeCode: tabInfo.code,
    teamId: tabInfo.teamId,
    channelTypeCode: tabInfo.channelTypeCode,
    languageCode: tabInfo.languageCode
  };

  const { data: tabInfoDetail = '' } = useQuery({
    queryKey: [QUERY_KEY.TAB_INFO_DETAIL, tabInfoPayload.teamId, tabInfoPayload.typeCode],
    queryFn: () => getSportsTabInfoDetail(tabInfoPayload),
    // TODO:II 테스트 후 수정 필요_QA 후 변경 예정
    staleTime: Infinity,
    enabled: Boolean(tabInfoPayload),
    select: (data) => data.data
  });

  return {
    tabInfoDetail
  };
};
