import { useQueries } from '@tanstack/react-query';
import { gnbBannerDateTime, isValidBanner } from '@tkl-packages/components/src/ticketlink/Header/Gnb/utils';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { TeamBanner, MergedBanner, CategoryListType } from '@tkl-packages/models';
import { isFacility, isPc } from '@tkl-packages/sdk/src/validations';
import {
  getFacilityCategoryListApi,
  getSportCategoryListByIdApi,
  getTeamBannersApi
} from '@tkl-packages/services/src/mapi';
import dayjs from 'dayjs';

export const useSportsBanners = (categoryId: string, teamId: string, partnerId?: string) => {
  const sportsEventsQuery = {
    queryKey: [QUERY_KEY.SPORTS_EVENTS, categoryId],
    queryFn: () => getSportCategoryListByIdApi(categoryId),
    enabled: categoryId !== '',
    // TODO:II 테스트 후 수정 필요_QA 후 변경 예정
    staleTime: Infinity
  };

  const bannersQuery = {
    queryKey: [QUERY_KEY.SPORTS_TEAM_BANNERS, teamId],
    queryFn: () => getTeamBannersApi(teamId),
    // TODO:II 테스트 후 수정 필요_QA 후 변경 예정
    staleTime: Infinity,
    enabled: teamId !== ''
  };

  const facilityEventsQuery = {
    queryKey: [QUERY_KEY.PLANNING_FACILITY_CATEGORY_LIST],
    queryFn: () => getFacilityCategoryListApi(),
    // TODO:II 테스트 후 수정 필요_QA 후 변경 예정
    staleTime: Infinity,
    enabled: isFacility() && !!partnerId
  };

  return useQueries({
    queries: [bannersQuery, sportsEventsQuery, facilityEventsQuery],
    combine: (res) => {
      const [bannersRes, sportsEventsRes, facilityRes] = res;
      const banners = bannersRes.data?.data ?? [];
      const sportsEvents = sportsEventsRes.data ?? [];
      const facilityEvents = facilityRes.data ?? [];

      const bannersTransformed = banners
        ? banners.map((banner: TeamBanner | null | undefined): MergedBanner | null => {
            if (!banner) return null;

            const { bannerId, bannerTitle, imagePath, bannerUrl, bannerAppUrl, bannerUrlTargetCode, bannerTargetCode } =
              banner;

            if (!bannerId || !bannerTitle) return null;

            return {
              id: bannerId,
              title: bannerTitle,
              url: bannerUrl,
              imageUrl: imagePath || '',
              type: 'banner',
              target: bannerUrlTargetCode,
              appUrl: bannerAppUrl,
              appTarget: bannerTargetCode
            };
          })
        : [];
      const eventsTransformed = sportsEvents
        ? sportsEvents.map((event: CategoryListType | null | undefined): MergedBanner | null => {
            if (!event) return null;

            const { planningEventId, planningEventName, imageUrl, exposureDate, teamId: eventTeamId } = event;

            if (parseInt(teamId) !== eventTeamId) return null;
            if (!planningEventId || !planningEventName) return null;
            if (
              !isValidBanner({
                bannerExposureYn: 'Y',
                exposureStartDatetime: gnbBannerDateTime(dayjs(exposureDate.startDate)),
                exposureEndDatetime: gnbBannerDateTime(dayjs(exposureDate.endDate))
              })
            ) {
              return null;
            }

            return {
              id: planningEventId,
              title: planningEventName,
              imageUrl: imageUrl || '',
              type: 'sport',
              url: planningEventId ? `/sports/${categoryId}/planning-event/${planningEventId}` : ''
            };
          })
        : [];

      const facilityTransformed = facilityEvents
        ? facilityEvents.map((event: CategoryListType | null | undefined): MergedBanner | null => {
            if (!event) return null;

            const { planningEventId, planningEventName, imageUrl, exposureDate, partnerNo } = event;

            if (!partnerId || parseInt(partnerId) !== partnerNo) return null;
            if (!planningEventId || !planningEventName) return null;
            if (
              !isValidBanner({
                bannerExposureYn: 'Y',
                exposureStartDatetime: gnbBannerDateTime(dayjs(exposureDate.startDate)),
                exposureEndDatetime: gnbBannerDateTime(dayjs(exposureDate.endDate))
              })
            ) {
              return null;
            }

            return {
              id: planningEventId,
              title: planningEventName,
              imageUrl: imageUrl || '',
              type: 'sport',
              url: planningEventId ? `/sports/${categoryId}/planning-event/${planningEventId}` : ''
            };
          })
        : [];

      if (isPc()) {
        return [...eventsTransformed].filter(Boolean);
      }

      if (isFacility()) {
        return [...bannersTransformed, ...facilityTransformed];
      }

      return [...bannersTransformed, ...eventsTransformed].filter(Boolean);
    }
  });
};
