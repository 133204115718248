import { Navigate, Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeaderGlobal from '../../components/HTMLHeader/NormalHeader.global';
import ProductHeader from '../../components/HTMLHeader/ProductHeader';
import { ProductGlobal } from '../../pages/global/pc';

const ProductRouteGlobal = () => {
  return (
    <Routes>
      <Route
        path=":productId"
        element={
          <NormalHeaderGlobal>
            <ProductHeader>
              <Channel
                pc={<ProductGlobal />}
                // TODO:II:GLOBAL_MOBILE
                mobile={<ProductGlobal />}
                // mobile={ <ProductGlobalMobile /> }
              />
            </ProductHeader>
          </NormalHeaderGlobal>
        }
      />
      <Route path="*" element={<Navigate to=".." />} />
    </Routes>
  );
};

export default ProductRouteGlobal;
