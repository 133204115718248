import { Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeaderGlobal from '../../components/HTMLHeader/NormalHeader.global';
import { MainGlobal } from '../../pages/global/pc';

const HomeRouteGlobal = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeaderGlobal>
            <Channel
              pc={<MainGlobal />}
              // TODO:II:GLOBAL_MOBILE
              mobile={<MainGlobal />}
              // mobile={<MainGlobalMobile /> }
            />
          </NormalHeaderGlobal>
        }
      />
    </Routes>
  );
};

export default HomeRouteGlobal;
