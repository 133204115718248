import { useNavigate } from 'react-router-dom';
import { APP_SCHEME, callAppScheme } from '@tkl-packages/constants/appScheme';
import { PRODUCT_CHANNEL } from '@tkl-packages/constants/product';
import { Product, RankingProduct } from '@tkl-packages/models';
import { GlobalView } from '@tkl-packages/sdk/src/utils';
import { isAppWeb } from '@tkl-packages/sdk/src/validations';
import { isLibraryMode } from '@tkl-packages/sdk/src/vite';

const useProductDetailRoute = ({ searchType, productId }: Product | RankingProduct) => {
  const navigate = useNavigate();
  const goProductDetail = () => {
    const isLinkOn = searchType === PRODUCT_CHANNEL.ONLINE;

    if (isAppWeb()) {
      callAppScheme(isLinkOn ? APP_SCHEME.LINK_ON : APP_SCHEME.PRODUCT, { productId });
    } else if (isLinkOn) {
      window.location.href = GlobalView.applyPath(`/linkon/product/${productId}`);
    } else {
      const nextPath = GlobalView.applyPath(`/product/${productId}`);
      if (isLibraryMode()) {
        window.location.href = nextPath;
      } else {
        navigate(nextPath);
      }
    }
  };

  return { goProductDetail };
};

export default useProductDetailRoute;
