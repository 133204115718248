import { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoComplete } from '@tkl-apps/ticketlink/src/components/Search';
import useSearch from '@tkl-apps/ticketlink/src/hooks/search/useSearch';
import useOutsideClick from '@tkl-packages/hooks/src/useOutsideClick';
import { GlobalView } from '@tkl-packages/sdk/src/utils';

interface props {
  query?: string;
}

const SearchBox = ({ query }: props) => {
  const { t } = useTranslation();
  const pcWebPath = GlobalView.replacePCWebServerPath('/search');
  const searchPath = GlobalView.applyPath(pcWebPath);

  const { keyword, searchKeyword, isShowAutoComplete, setIsShowAutoComplete, inputRef, onChangeKeyword, onSearch } =
    useSearch({
      query,
      path: searchPath
    });

  const onEnterHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  const { ref } = useOutsideClick(() => setIsShowAutoComplete(false));

  return (
    <div className="header_search">
      <div className="header_search_box">
        <input
          ref={inputRef}
          type="search"
          name="search"
          id="search"
          className="header_search_input"
          placeholder={GlobalView.isGlobal() ? t('아티스트, 이벤트, 지역 검색') : t('검색어를 입력해 주세요')}
          value={keyword}
          onChange={onChangeKeyword}
          onKeyPress={onEnterHandler}
        />
        <button type="button" className="header_search_btn" onClick={onSearch}>
          <span className="common_icon icon_search ty_sm">
            <span className="blind">{t('검색')}</span>
          </span>
        </button>
      </div>
      {isShowAutoComplete && <AutoComplete keyword={searchKeyword} ref={ref} />}
    </div>
  );
};

export default SearchBox;
