import { Navigate, Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import { GlobalView } from '@tkl-packages/sdk/src/utils';
import NormalHeaderGlobal from '../../components/HTMLHeader/NormalHeader.global';
import {
  GuideReservationGlobal,
  GuideTicketGlobal,
  FaqGlobal,
  FaqDetailGlobal,
  GuideSignUpGlobal
} from '../../pages/global/pc';

const HelpRouteGlobal = () => {
  return (
    <Routes>
      <Route path="faq/*">
        <Route
          index
          element={
            <NormalHeaderGlobal>
              <Channel
                pc={<FaqGlobal />}
                // TODO:II:GLOBAL_MOBILE
                mobile={<FaqGlobal />}
              />
            </NormalHeaderGlobal>
          }
        />
        <Route
          path=":faqId"
          element={
            <NormalHeaderGlobal>
              <Channel
                pc={<FaqDetailGlobal />}
                // TODO:II:GLOBAL_MOBILE
                mobile={<FaqDetailGlobal />}
              />
            </NormalHeaderGlobal>
          }
        />
      </Route>
      <Route path={'guide'}>
        <Route
          path="signup"
          element={
            <NormalHeaderGlobal>
              <Channel
                pc={<GuideSignUpGlobal />}
                // TODO:II:GLOBAL_MOBILE
                mobile={<GuideSignUpGlobal />}
              />
            </NormalHeaderGlobal>
          }
        />
        <Route
          path="reservation"
          element={<Navigate to={GlobalView.applyPath('/help/guide/reservation/baseball')} replace />}
        />
        <Route
          path="reservation/:param"
          element={
            <NormalHeaderGlobal>
              <Channel
                pc={<GuideReservationGlobal />}
                // TODO:II:GLOBAL_MOBILE
                mobile={<GuideReservationGlobal />}
              />
            </NormalHeaderGlobal>
          }
        />
        <Route
          path="ticket"
          element={
            <NormalHeaderGlobal>
              <Channel
                pc={<GuideTicketGlobal />}
                // TODO:II:GLOBAL_MOBILE
                mobile={<GuideTicketGlobal />}
              />
            </NormalHeaderGlobal>
          }
        />
      </Route>
      <Route
        path="*"
        element={
          <NormalHeaderGlobal>
            <Navigate to=".." />
          </NormalHeaderGlobal>
        }
      />
      <Route path="*" element={<Navigate to=".." />} />
    </Routes>
  );
};

export default HelpRouteGlobal;
