// TODO:II:GLOBAL_MOBILE 푸터 작업 진행 시, 파일 전체 컨텐츠와 다국어 수정이 필요합니다.
// - 아직 마크업 확정 안 됨, 엘리먼트와 컨텐츠가 섞인, 다국어 확장 스타일 예시

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TKLink from '@tkl-packages/components/src/atoms/TKLink';
import useFooterAnchor from '@tkl-packages/hooks/src/useFooterAnchor';
import { GlobalView } from '@tkl-packages/sdk/src/utils';
import { isAppWeb } from '@tkl-packages/sdk/src/validations';

interface Props {
  useWrapUrl?: boolean;
}

const FooterGlobalMobile = ({ useWrapUrl = false }: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  const { movePersonalInformation, moveYouth, movePolicyUsage, moveBusinessInformation } = useFooterAnchor({
    useWrapUrl
  });

  const { t } = useTranslation();

  return (
    <footer className="m_footer ">
      <div className="footer_inner">
        <div className="footer_toggle">
          <button
            type="button"
            className="footer_toggle_btn"
            onClick={() => setIsOpened((prev) => !prev)}
            aria-expanded={isOpened}
          >
            <span className="text">{t('엔에이치엔링크 주식회사')}</span>
            <span className="blind">{t('사업자 정보 자세히 보기')}</span>
          </button>
          <div className="footer_toggle_cont">
            <FooterLocaleContent />
          </div>
        </div>
        <ul className="footer_term_list">
          <li className="footer_term_item">
            <TKLink
              to={movePolicyUsage}
              className="footer_term_link"
              target={isAppWeb() ? '_self' : '_blank'}
              rel="noreferrer"
              useNative={useWrapUrl}
            >
              {t('이용약관')}
            </TKLink>
          </li>
          <li className="footer_term_item">
            <TKLink
              to={movePersonalInformation}
              className="footer_term_link"
              target={isAppWeb() ? '_self' : '_blank'}
              rel="noreferrer"
              useNative={useWrapUrl}
            >
              <span className="text_bold" style={{ fontWeight: 'bold' }}>
                {t('개인정보 처리방침')}
              </span>
            </TKLink>
          </li>
          <li className="footer_term_item">
            <TKLink
              to={moveYouth}
              className="footer_term_link"
              target={isAppWeb() ? '_self' : '_blank'}
              rel="noreferrer"
              useNative={useWrapUrl}
            >
              {t('청소년 보호정책')}
            </TKLink>
          </li>
          <li className="footer_term_item">
            <TKLink to={moveBusinessInformation} className="footer_term_link" useNative={useWrapUrl}>
              {t('사업자정보')}
            </TKLink>
          </li>
        </ul>
        <span className="footer_copyright">Copyright © NHN LINK Corp. All rights reserved.</span>
      </div>
    </footer>
  );
};

export default FooterGlobalMobile;

const FooterLocaleContent = () => {
  const locale = GlobalView.getGlobalLocale();
  switch (locale) {
    case 'ja':
      return <FooterLocaleJa />;
    case 'zh':
      return <FooterLocaleZh />;
    case 'en':
    default:
      return <FooterLocaleEn />;
  }
};

function FooterLocaleEn() {
  return (
    <>
      •J Building, 586, Gangnam-daero, Gangnam-gu, Seoul, Republic of Korea, 06043Chief Executive Officer : Wang Moon Ju
      |Business Registration Number 144-81-25090 •Telecommunication sale business registration number : 2023-Seoul
      Gangnam-04352 •Customer Service : membership@nhnlink.co.kr / 1644-3850 (only english)
    </>
  );
}
function FooterLocaleJa() {
  return (
    <>
      •チケットリンク 住所：ソウル特別市江南区江南大路586、ジェイビル, 06043代表取締役 王文柱
      |事業者登録番号：144-81-25090 •通信販売業申告番号： 第2023-ソウル江南-04352号 •カスタマーセンター :
      membership@nhnlink.co.kr / 1644-3850 (英語による対応のみ可能です）
    </>
  );
}
function FooterLocaleZh() {
  return (
    <>
      •Ticket Link所在地：尔特别市江南区江南大路586, J大厦, 06043代表理事 王文柱 |营业执照编号：144-81-25090
      •通信贩卖业申请编号： 第2023-首爾江南-04352號 •客服中心 : membership@nhnlink.co.kr / 1644-3850 (只提供英语咨询）
    </>
  );
}
