import { APP_SCHEME, appScheme } from '@tkl-packages/constants/appScheme';
import { BANNER_CODE, WEB_VIEW_TARGET_CODES } from '@tkl-packages/constants/banner';
import { APP_TARGET_TYPE, TARGET_TYPE } from '@tkl-packages/constants/common';
import { CategoryComponentType } from '@tkl-packages/models';
import { isWeb } from '@tkl-packages/sdk/src/validations';

interface GetMobileBannerLinkInfoParamType {
  appTarget: string;
  appUrl: string;
  webTarget: string;
  webUrl: string;
}

type GetMobileBannerLinkInfo = (p: GetMobileBannerLinkInfoParamType) => {
  url: string;
  target: string;
};

const getMobileBannerLinkInfo: GetMobileBannerLinkInfo = ({ appTarget, appUrl, webTarget, webUrl }) => {
  if (isWeb()) {
    return {
      url: webUrl,
      target: webTarget === TARGET_TYPE.BLANK ? '_blank' : '_self'
    };
  }

  const url =
    appUrl === ''
      ? '#'
      : appScheme(APP_SCHEME.MOVE_BANNER, {
          type: WEB_VIEW_TARGET_CODES.includes(appTarget) ? APP_TARGET_TYPE.WEB_VIEW : APP_TARGET_TYPE.WEB_BROWSER,
          linkUrl: appUrl
        });

  return {
    url,
    target: '_self'
  };
};

const getBannerCode = (category: CategoryComponentType) => {
  switch (category?.code) {
    case '10':
      return BANNER_CODE.PERFORMANCE_BANNER;
    case '16':
      return BANNER_CODE.MUSICAL_BANNER;
    case '15':
      return BANNER_CODE.THEATER_BANNER;
    case '14':
      return BANNER_CODE.CONCERT_BANNER;
    case '18':
      return BANNER_CODE.CLASSIC_BANNER;
    case '84':
      return BANNER_CODE.DAEHAKRO_BANNER;
    case '85':
      return BANNER_CODE.FAMILY_BANNER;
    case '11':
      return BANNER_CODE.EXHIBITION_BANNER;
    case '24':
      return BANNER_CODE.FESTIVAL_BANNER;
    case '65':
      return BANNER_CODE.LEISURE_BANNER;
    default:
      return BANNER_CODE.NOTFOUND;
  }
};

export { getMobileBannerLinkInfo, getBannerCode };
