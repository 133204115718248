import { Navigate, Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeaderGlobal from '../../components/HTMLHeader/NormalHeader.global';
import { ExhibitionGlobal } from '../../pages/global/pc';

const ExhibitionRouteGlobal = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeaderGlobal>
            <Channel
              pc={<ExhibitionGlobal />}
              // TODO:II:GLOBAL_MOBILE
              mobile={<ExhibitionGlobal />}
            />
          </NormalHeaderGlobal>
        }
      />
      <Route
        path=":category"
        element={
          <NormalHeaderGlobal>
            <Channel
              pc={<ExhibitionGlobal />}
              // TODO:II:GLOBAL_MOBILE
              mobile={<ExhibitionGlobal />}
            />
          </NormalHeaderGlobal>
        }
      />
      <Route path="*" element={<Navigate to=".." />} />
    </Routes>
  );
};

export default ExhibitionRouteGlobal;
