import { useTranslation } from 'react-i18next';

const ReloadButton = () => {
  const { t } = useTranslation();
  return (
    <button type="button" className="toolbar_refresh" onClick={() => window.location.reload()}>
      <span className="blind">{t('새로고침')}</span>
    </button>
  );
};

export default ReloadButton;
