export const BASE_APP_SCHEME_URL = 'ticketlink://';

export const APP_SCHEME = {
  CLOSE_WEB_VIEW: 'closeWebView',
  LOGIN: 'login',
  LOGOUT: 'logout',
  HOME: 'home',
  TOUR_CATEGORY: 'tourCategory',
  LINK_ON: 'linkon',
  EVENT_DETAIL: 'eventDetail',
  NOTICE: 'notice',
  HELP: 'help',
  COUPON: 'coupon',
  AVAILABLE_COUPON: 'availableCoupon',
  SETTING: 'setting',
  INQUIRY_HISTORY: 'inquiryHistory',
  TOUR_RESERVE_LIST: 'tourReserveList',
  WAITING_RESERVATION: 'waitingReservation',
  NOTIFICATION_LIST: 'notificationList',
  NOTIFICATION_SETTING: 'notificationSetting',
  OPEN_SOURCE_LICENSE: 'openSourceLicense',
  CERTIFICATION_IDENTITY: 'certificationIdentity',
  FACE_ENTRANCE_REGISTER: 'faceEntranceRegister',
  INQUIRY_ONE_ON_ONE: 'inquiryOneOnOne',
  RESERVE_CLOSE: 'reserveClose',
  RESERVE: 'reserve',
  CAPTCHA: 'captcha',
  OPEN_RESERVATION: 'openReservation',
  EXTERNAL_LINK: 'externalLink',
  ADULT: 'adult',
  NOT_SHOW_UP: 'notShowUp',
  SMART_TICKET_DETAIL: 'smartTicketDetail',
  PRODUCT: 'product',
  FAN_CLUB: 'fanClub',
  MOVE_BANNER: 'moveBanner',
  CASTING_SCHEDULE: 'castingSchedule',
  SHARE: 'share',
  KAKAO_SHARE: 'kakaoshare',
  BAND_SHARE: 'bandshare',
  CLUB_PRODUCT_LIST: 'clubProductList',
  PLAN_EXHIBITION: 'planExhibition', // 공연장
  SPORTS_CATEGORY: 'sportsCategory',
  SPORTS_EVENT: 'sportsEvent',
  FACILITY_LOGIN: 'facilityLogin',
  FACILITY_LOGOUT: 'facilityLogout',
  TERM_PERSONAL_INFORMATION: 'termPersonalInfomation',
  TERM_YOUTH: 'termYouth',
  TERM_USE_AGREEMENT: 'termUseAgreement',
  TERM_BUSINESS_INFORMATION: 'termBusinessInfomation',
  PLANNING: 'planning', // 기획전
  DEVICE_AUTH: 'deviceAuth'
} as const;

type Scheme = (typeof APP_SCHEME)[keyof typeof APP_SCHEME];
type SchemeParams = Record<string, any>;

/**
 * appScheme 함수
 * @param scheme
 * @param params
 * @example appScheme(APP_SCHEME.PLANNING, { type: 'exhibition', categoryId: 137 }))
 * @returns 'ticketlink://planning?type=exhibition&categoryId=137'
 */
export const appScheme = (scheme: Scheme, params?: SchemeParams): string => {
  const cleanParams: Record<string, string> = {};
  if (params) {
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        cleanParams[key] = params[key] as string;
      }
    });
  }
  // const queryString = Object.keys(cleanParams).length > 0 ? '?' + new URLSearchParams(cleanParams).toString() : '';
  const queryString = Object.keys(cleanParams).length > 0 ? '?' + toURLParams(cleanParams) : '';

  return `${BASE_APP_SCHEME_URL}${scheme}${queryString}`;
};

function toURLParams(params: SchemeParams) {
  return Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
}

export const callAppScheme = (scheme: Scheme, params?: SchemeParams) => {
  window.location.href = appScheme(scheme, params);
};
