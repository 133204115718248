import { useTranslation } from 'react-i18next';
import { FullScreenModalMobile } from '@tkl-packages/components/src/ticketlink/Modal';

interface props {
  onClose: () => void;
}

const DeviceAuthMobile = ({ onClose }: props) => {
  const { t } = useTranslation();

  return (
    <FullScreenModalMobile title={t('기기 인증 안내')} onClose={onClose}>
      <>
        <h4 className="popup_title">{t('기기 인증 서비스란')}</h4>
        <p className="popup_desc">
          {t(
            '예매자 본인 확인 절차를 강화하기 위한 서비스로, 사용하는 휴대폰 기기를 티켓링크 앱에서 인증한 다음 기기 인증 상품을 이용할 수 있습니다.'
          )}
        </p>
        <p className="popup_desc">
          {t(
            '기기 인증 상품은 티켓링크 앱에서만 이용할 수 있습니다. 회원이 인증한 기기와 접속한 기기 정보를 비교해서 일치하는 경우에만 예매 및 티켓 확인이 가능합니다.'
          )}
        </p>
      </>
    </FullScreenModalMobile>
  );
};

export default DeviceAuthMobile;
