const COOKIE_KEY = {
  MAIN_MODAL_SHOW: 'MAIN_MODAL_SHOW',
  SPORTS_MAIN_MODAL_SHOW: 'SPORTS_MAIN_MODAL_SHOW',
  SPORTS_TEAM_MAIN_MODAL_SHOW: 'SPORTS_TEAM_MAIN_MODAL_SHOW',
  SPORTS_TEAM_NOTICE_SHOW: 'SPORTS_TEAM_NOTICE_SHOW',
  BUGS_GUIDE_SHOW: 'BUGS_GUIDE_SHOW',
  API_TOKEN: 'TKLINK_SES', // 국내 전용
  GLOBAL_API_TOKEN: 'TKGLOBAL_SES', // 글로벌 전용
  API_FACILITY_TOKEN: 'FA_TKLINK',
  APP_VERSION: 'APP_VERSION',
  APP_PUSH_USE: 'APP_PUSH_USE',
  LOGIN_TOKEN: 'TKLINK',
  FACILITY_LOGIN_TOKEN: 'FA_TKLINK',
  TKLINK_ID: 'VIEW_TKLINK_ID',
  FACILITY_ID: 'FA_VIEW_ID',
  HOME_TYPE: 'HOME_TYPE',
  TODAY_VIEW_PRODUCTS: 'TODAY_VIEW_PRODUCTS',
  PAYCO_VIP_FLAG: 'TKLINK_PAYCO_VIP_FLAG',
  MASKED_EMAIL_ID: 'VIEW_TKLINK_ID', // 국내 전용
  TKGLOBAL_MASKING_ID: 'TKGLOBAL_MASKING_ID', // 글로벌 전용 ( 미사용 )
  BENEPIA: 'BENEPIA',
  ENCRYPTED_CI: 'ENCRYPTED_CI',
  DEVICE_ID: 'TKL_MEMBER_DEVICE_ID',
  DEVICE_NAME: 'TKL_MEMBER_DEVICE_NAME'
} as const;

const TKL_LOCALE_KEY = 'i18nextLng';
const TKL_LOCALE_HEADER_KEY = 'X-Tl-Locale';
const TKL_LOCALE_MAP = {
  KO: 'ko',
  EN: 'en',
  JA: 'ja',
  ZH: 'zh'
} as const;

export { COOKIE_KEY, TKL_LOCALE_KEY, TKL_LOCALE_HEADER_KEY, TKL_LOCALE_MAP };
