import {
  CLASSIC,
  CONCERT,
  DAEHAKRO,
  EXHIBITION,
  FAMILY,
  FESTIVAL,
  LEISURE,
  MUSICAL,
  PERFORMANCE,
  PLAY
} from '@tkl-packages/constants/category';

const CATEGORIES = [
  {
    path: `/performance/${MUSICAL}`,
    code: 'musical',
    name: '뮤지컬'
  },
  {
    path: `/performance/${PLAY}`,
    code: 'play',
    name: '연극'
  },
  {
    path: `/performance/${CONCERT}`,
    code: 'concert',
    name: '콘서트'
  },
  {
    path: `/performance/${CLASSIC}`,
    code: 'classic',
    name: '클래식'
  },
  {
    path: `/exhibition/${EXHIBITION}`,
    code: 'exhibit',
    name: '전시/레저'
  }
] as const;

const PERFORMANCE_CATEGORIES = [
  {
    code: PERFORMANCE,
    name: '전체',
    isRequired: true
  },
  {
    code: MUSICAL,
    name: '뮤지컬',
    isRequired: true
  },
  {
    code: PLAY,
    name: '연극',
    isRequired: true
  },
  {
    code: CONCERT,
    name: '콘서트',
    isRequired: true
  },
  {
    code: CLASSIC,
    name: '클래식',
    isRequired: true
  },
  {
    code: DAEHAKRO,
    name: '대학로',
    isRequired: false
  },
  {
    code: FAMILY,
    name: '아동/가족',
    isRequired: true
  }
] as const;

const EXHIBITION_CATEGORIES = [
  {
    code: EXHIBITION,
    name: '전체',
    subCategory: []
  },
  {
    code: FESTIVAL,
    name: '전시/축제',
    subCategory: [
      {
        code: FESTIVAL,
        name: '전체'
      },
      {
        code: '49',
        name: '전시'
      },
      {
        code: '50',
        name: '축제'
      }
    ]
  },
  {
    code: LEISURE,
    name: '레저/체험',
    subCategory: [
      {
        code: LEISURE,
        name: '전체'
      },
      {
        code: '66',
        name: '레저'
      },
      {
        code: '67',
        name: '체험'
      }
    ]
  }
] as const;

const PRODUCT_SORTING_OPTIONS = [
  {
    name: '일간 랭킹순',
    code: 'DAILY_RANKING'
  },
  {
    name: '주간 랭킹순',
    code: 'WEEKLY_RANKING'
  },
  {
    name: '종료 임박순',
    code: 'END_IMMINENT'
  }
] as const;

const LOCAL_CATEGORIES = [
  {
    name: '전체',
    code: ''
  },
  {
    name: '서울',
    code: 'SEOUL'
  },
  {
    name: '경기/인천',
    code: 'GYEONGGI'
  },
  {
    name: '충청/강원',
    code: 'GANGWON_CHOONGCHUNG'
  },
  {
    name: '대구/경북',
    code: 'GYEONGBUK'
  },
  {
    name: '부산/경남',
    code: 'GYEONGNAM'
  },
  {
    name: '광주/전라',
    code: 'JEONLA'
  },
  {
    name: '제주',
    code: 'JEJU'
  }
] as const;

const LOCAL_SORTING_OPTIONS = [
  {
    name: '추천순',
    code: 'RECOMMENDED_ORDER'
  },
  {
    name: '종료일 늦은순',
    code: 'LATE_END_DATE_ORDER'
  },
  {
    name: '최근 등록순',
    code: 'LAST_REGISTRATION_ORDER'
  }
] as const;

const DETAIL_TAB = {
  DETAIL: 'DETAIL',
  REVIEW: 'REVIEW',
  PREVIEW: 'PREVIEW',
  PLACE: 'PLACE',
  GUIDE: 'GUIDE',
  ADDITION: 'ADDITION'
} as const;

const DETAIL_TABS = [
  { name: '상세정보', code: DETAIL_TAB.DETAIL },
  { name: '관람후기', code: DETAIL_TAB.REVIEW },
  { name: '기대평', code: DETAIL_TAB.PREVIEW },
  { name: '장소정보', code: DETAIL_TAB.PLACE },
  { name: '예매/취소안내', code: DETAIL_TAB.GUIDE },
  { name: '부가상품', code: DETAIL_TAB.ADDITION }
] as const;

const MOBILE_MAIN_PRODUCT = {
  TICKET_OPEN: 'MOBILE_TICKET_OPEN',
  PLAN: 'MOBILE_PLAN_EXHIBITION'
} as const;

const MAIN_PRODUCT = {
  PAYCO_VIP: 'WEB_PAYCO_VIP',
  BEST_SHOW: 'WEB_BEST_V1',
  TICKET_OPEN: 'WEB_TICKET_OPEN'
} as const;

const SALE_STATE = {
  BEFORE: 'BEFORE',
  SALE: 'SALE',
  END: 'END'
} as const;

const REVIEW_TYPE = {
  REVIEW: 'REVIEW',
  PREVIEW: 'PREVIEW'
} as const;

const PRODUCT_CATEGORY = {
  PERFORMANCE: 'CONCERT',
  EXHIBITION: 'EXHIBITION',
  SPORTS_SEAT: 'SPORTS_SEAT',
  SEASON_TICKET: 'SEASON_TICKET',
  ADVANCE_TICKET: 'ADVANCE_TICKET',
  SPORTS_ZONE: 'SPORTS_ZONE'
} as const;

const SHOW_NAME = {
  PERFORMANCE: '공연',
  EXHIBITION: '전시'
} as const;

const FLAG_TYPE = {
  MAIN: 'main',
  CUSTOM: 'custom'
} as const;

const PRODUCT_CHANNEL = {
  OFFLINE: 'COEX',
  ONLINE: 'LINK_ON'
} as const;

const EVENT_TYPE = {
  ENTRY: 'ENTRY',
  INVITE: 'INVITE',
  GIFT: 'GIFT'
} as const;

const EVENT_TEMPLATE = {
  BASIC: 'BASIC',
  CUSTOM: 'CUSTOM',
  NONE: 'NONE'
} as const;

const VIEW_GRADE = {
  AGE: 'AGE',
  TYPE: 'TYPE'
} as const;

const VIEW_AGE_CODE = {
  '0': '전체이용',
  '14': '14세이상',
  '19': '19세이상',
  FROM_2: '2세이상',
  FROM_3: '3세이상',
  FROM_4: '4세이상',
  FROM_5: '5세이상',
  FROM_6: '6세이상',
  FROM_7: '7세이상',
  FROM_8: '8세이상',
  FROM_10: '10세이상',
  FROM_12: '12세이상',
  FROM_13: '13세이상',
  FROM_14: '14세이상',
  FROM_15: '15세이상',
  FROM_16: '16세이상',
  FROM_17: '17세이상',
  FROM_18: '18세이상',
  FROM_19: '19세이상',
  OVER_2: '만2세이상',
  OVER_3: '만3세이상',
  OVER_4: '만4세이상',
  OVER_5: '만5세이상',
  OVER_6: '만6세이상',
  OVER_7: '만7세이상',
  OVER_8: '만8세이상',
  OVER_10: '만10세이상',
  OVER_12: '만12세이상',
  OVER_13: '만13세이상',
  OVER_14: '만14세이상',
  OVER_15: '만15세이상',
  OVER_16: '만16세이상',
  OVER_17: '만17세이상',
  OVER_18: '만18세이상',
  OVER_19: '만19세이상',
  FROM_9: '9세이상',
  FROM_11: '11세이상',
  OVER_9: '만9세이상',
  OVER_11: '만11세이상'
} as const;

const VIEW_TYPE_CODE = {
  NORMAL: '일반',
  TEEN: '청소년관람가',
  ADULT: '성인',
  '12': 'MONTHS	12개월이상',
  '24': 'MONTHS	24개월이상',
  '36': 'MONTHS	36개월이상',
  '48': 'MONTHS	48개월이상',
  ALL: '전체관람가',
  NOT_PRESCHOOLER: '미취학아동입장불가',
  OVER_ELEMENTARY: '초등학생이상관람가',
  OVER_MIDDLE: '중학생이상관람가',
  MINOR: '연소자관람가',
  NOT_MINOR: '연소자관람불가',
  NOT_TEEN: '청소년관람불가'
} as const;

const PRODUCT_FLAG = {
  SOLELY: 'SOLELY',
  SEAT: 'SEAT',
  TICKET_OPEN: 'TICKET_OPEN'
} as const;

const PRODUCT_FLAG_NAME = {
  [PRODUCT_FLAG.SOLELY]: '단독 판매',
  [PRODUCT_FLAG.SEAT]: '좌석 우위',
  [PRODUCT_FLAG.TICKET_OPEN]: '티켓 오픈'
} as const;

const ATTENTION_PRODUCT_DISPLAY_INFO = {
  [PERFORMANCE]: [SHOW_NAME.PERFORMANCE, 'SHOW_ISSUE_V1'],
  [EXHIBITION]: [SHOW_NAME.EXHIBITION, 'DISPLAY_ISSUE_V1']
} as const;

const RECOMMEND_PRODUCT_DISPLAY_INFO = {
  [PERFORMANCE]: [SHOW_NAME.PERFORMANCE, 'SHOW_RECOMMEND_V1'],
  [MUSICAL]: [SHOW_NAME.PERFORMANCE, 'MUSICAL_RECOMMEND_V1'],
  [PLAY]: [SHOW_NAME.PERFORMANCE, 'THEATER_RECOMMEND_V1'],
  [CLASSIC]: [SHOW_NAME.PERFORMANCE, 'CLASSIC_RECOMMEND_V1'],
  [CONCERT]: [SHOW_NAME.PERFORMANCE, 'CONCERT_RECOMMEND_V1'],
  [DAEHAKRO]: [SHOW_NAME.PERFORMANCE, 'DAEHAKRO_RECOM_V1'],
  [FAMILY]: [SHOW_NAME.PERFORMANCE, 'FAMILY_RECOMMEND_V1'],
  [EXHIBITION]: [SHOW_NAME.EXHIBITION, 'DISPLAY_RECOMMEND_V1'],
  [FESTIVAL]: [SHOW_NAME.EXHIBITION, 'FESTIVAL_RECOM_V1'],
  [LEISURE]: [SHOW_NAME.EXHIBITION, 'LEISURE_RECOMMEND_V1']
} as const;

const WAITING_RESEVE_CLOSE = {
  RESERVE_END_DATE: 'RESERVE_END_DATE',
  PRODUCT_DATE: 'PRODUCT_DATE',
  RESERVE_CLOSE: 'RESERVE_CLOSE'
} as const;

const WAITING_SALE_STATUS = {
  BEFORE: 'BEFORE', // 취소표대기 이전
  INSALE: 'INSALE', // 취소표대기 판매중
  CLOSED: 'CLOSED' // 취소표대기 이후
} as const;

const WAITING_MAX_COUNT_TYPE = {
  PRODUCT: 'PRODUCT', // 상품당
  SCHEDULE: 'SCHEDULE' // 회차당
} as const;

const WAITING_MAX_TICKET_COUNT_TYPE = {
  RESERVE: 'RESERVE', // 건당
  SCHEDULE: 'SCHEDULE', // 회차당
  MEMBER: 'MEMBER' // 인당
} as const;

const WAITING_RESERVATION_TYPE = {
  SEAT: 'SEAT', // 좌석형
  SECTION: 'SECTION', // 구역형
  COUNT: 'COUNT' // 매수형
} as const;

const AUTH_REINFORCE_POPUP_EXPOSURE_TYPE = {
  NONE: 'NONE', // 비노출
  ONCE_PER_DAY: 'ONCE_PER_DAY', // 하루에 1회
  ALWAYS: 'ALWAYS' // 항상 노출
} as const;

const LOCAL_PRODUCT_CARD_HEIGHT = 496;
const LOCAL_PRODUCT_MARGIN = 50;

const PRODUCT_CLASS_TYPE = {
  CONCERT: 'CONCERT', // 공연
  EXHIBITION: 'EXHIBITION', // 전시
  SPORTS_SEAT: 'SPORTS_SEAT', // 스포츠(지정)
  SPORTS_ZONE: 'SPORTS_ZONE', // 스포츠(비지정)
  SEASON_TICKET: 'SEASON_TICKET', // 시즌권
  ADVANCE_TICKET: 'ADVANCE_TICKET' // 예매권
} as const;

const PRODUCT_TYPE_CODE = {
  LEAGUE_SINGLE_HOME: 'LEAGUE_SINGLE_HOME', // 리그경기(단일홈팀)
  LEAGUE_MULTI_HOME: 'LEAGUE_MULTI_HOME', // 리그경기(복수홈팀)
  LEAGUE_TOURNAMENT: 'LEAGUE_TOURNAMENT', // 리그경기토너먼트
  SEASON_TICKET_SEAT: 'SEASON_TICKET_SEAT', // 시즌권(좌석)
  PARTNER_ADV_TICKET: 'PARTNER_ADV_TICKET', // 구단예매권
  DAY: 'DAY', // 당일권
  STAY: 'STAY', // 숙박권
  SEASON: 'SEASON', // 기간권
  FANCLUB: 'FANCLUB', // 팬클럽
  NONE: 'NONE' // 타입없음
} as const;

export {
  CATEGORIES,
  PERFORMANCE_CATEGORIES,
  EXHIBITION_CATEGORIES,
  PRODUCT_SORTING_OPTIONS,
  LOCAL_CATEGORIES,
  LOCAL_SORTING_OPTIONS,
  DETAIL_TAB,
  DETAIL_TABS,
  MOBILE_MAIN_PRODUCT,
  MAIN_PRODUCT,
  SALE_STATE,
  REVIEW_TYPE,
  SHOW_NAME,
  FLAG_TYPE,
  PRODUCT_CHANNEL,
  PRODUCT_CATEGORY,
  EVENT_TYPE,
  EVENT_TEMPLATE,
  VIEW_GRADE,
  VIEW_AGE_CODE,
  VIEW_TYPE_CODE,
  PRODUCT_FLAG,
  PRODUCT_FLAG_NAME,
  ATTENTION_PRODUCT_DISPLAY_INFO,
  RECOMMEND_PRODUCT_DISPLAY_INFO,
  WAITING_RESEVE_CLOSE,
  WAITING_SALE_STATUS,
  WAITING_MAX_COUNT_TYPE,
  WAITING_MAX_TICKET_COUNT_TYPE,
  WAITING_RESERVATION_TYPE,
  LOCAL_PRODUCT_CARD_HEIGHT,
  LOCAL_PRODUCT_MARGIN,
  AUTH_REINFORCE_POPUP_EXPOSURE_TYPE,
  PRODUCT_CLASS_TYPE,
  PRODUCT_TYPE_CODE
};
