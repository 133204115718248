import { lazy } from 'react';

const NotFoundGlobalMobile = lazy(() => import(/* webpackChunkName: "not-found-m" */ './Error/NotFound'));

const MainGlobalMobile = lazy(() => import(/* webpackChunkName: "main-m" */ './Main.global.mobile'));

const CategoryGlobalMobile = lazy(() => import(/* webpackChunkName: "category-m" */ './Category'));

const ProductGlobalMobile = lazy(() => import(/* webpackChunkName: "product-m" */ './Product/Product'));

const SearchGlobalMobile = lazy(() => import(/* webpackChunkName: "search-m" */ './Search/Search'));
const SearchResultGlobalMobile = lazy(() => import(/* webpackChunkName: "search-result-m" */ './Search/Result'));

const MyGlobalMobile = lazy(() => import(/* webpackChunkName: "my-m" */ './User/My'));
const TicketGlobalMobile = lazy(() => import(/* webpackChunkName: "ticket-m" */ './User/Ticket'));
const WithdrawalGlobalMobile = lazy(() => import(/* webpackChunkName: "withdrawal-m" */ './User/Withdrawal'));

const PrivacyPolicyGlobalMobile = lazy(
  () => import(/* webpackChunkName: "privacy-policy-m" */ './Policy/PrivacyPolicy')
);
const UsagePolicyGlobalMobile = lazy(() => import(/* webpackChunkName: "usage-policy-m" */ './Policy/UsagePolicy'));

const HelpGlobalMobile = lazy(() => import(/* webpackChunkName: "help-m" */ './Help/Help'));

// TODO: 작엄 범위 완료 후, 제거
const DummyGlobalMobile = lazy(() => import(/* webpackChunkName: "main" */ './Dummy/Dummy'));

// const GuidePopupSmartTicketGlobalMobile = lazy(
//   () => import(/* webpackChunkName: "guide-popup-smart-ticket" */ './Help/Guide/Popup/SmartTicket')
// );
// const GuideWaitingReservationGlobalMobile = lazy(
//   () => import(/* webpackChunkName: "guide-waiting-reservation-m" */ './Help/Guide/WaitingReservation')
// );

// // 기존 모바일 코드 참고.
// const ExhibitionGlobalMobile = lazy(() => import(/* webpackChunkName: "exhibition-m" */ './Product/Exhibition'));
// const LocalGlobalMobile = lazy(() => import(/* webpackChunkName: "local-m" */ './Product/Local'));
// const PerformanceGlobalMobile = lazy(() => import(/* webpackChunkName: "performance-m" */ './Product/Performance'));
// const PlanGlobalMobile = lazy(() => import(/* webpackChunkName: "plan-m" */ './Product/Plan'));
// const PlanningGlobalMobile = lazy(() => import(/* webpackChunkName: "planning-m" */ './Planning/Planning'));

// const SportsTeamMainGlobalMobile = lazy(() => import(/* webpackChunkName: "sports-team-main-m" */ './Sports/TeamMain'));

// const LoginGlobalMobile = lazy(() => import(/* webpackChunkName: "login-m" */ './User/Login'));

// const CertificationIdentityGlobalMobile = lazy(
//   () => import(/* webpackChunkName: "certification-identity-m" */ './User/Certification/Identity')
// );
// const FaceEntranceGlobalMobile = lazy(() => import(/* webpackChunkName: "face-entrance-m" */ './User/Face/Entrance'));
// const FaceRegisterGlobalMobile = lazy(() => import(/* webpackChunkName: "face-register-m" */ './User/Face/Register'));
// const FaceTermsGlobalMobile = lazy(() => import(/* webpackChunkName: "face-register-m" */ './User/Face/Terms'));
// const DeviceAuthGlobalMobile = lazy(() => import(/* webpackChunkName: "device-auth-m" */ './User/Device/Auth'));
// const DeviceRegisterGlobalMobile = lazy(() => import(/* webpackChunkName: "device-register-m" */ './User/Device/Register'));

// const AdvanceTicketGlobalMobile = lazy(() => import(/* webpackChunkName: "advance-ticket-m" */ './User/AdvanceTicket'));

// const NotificationGlobalMobile = lazy(() => import(/* webpackChunkName: "notification-m" */ './User/Notification'));
// const NotificationSettingGlobalMobile = lazy(
//   () => import(/* webpackChunkName: "notification-setting-m" */ './User/NotificationSetting')
// );
// const RenewalTicketMobile4Sns = lazy(
//   () => import(/* webpackChunkName: "RenewalTicketMobile" */ './Ticket/Sns/MobileTicket4Sns')
// );
// const RenewalTicketMobile4Email = lazy(
//   () => import(/* webpackChunkName: "RenewalTicketMobile" */ './Ticket/Email/MobileTicket4Email')
// );

export {
  DummyGlobalMobile,
  MainGlobalMobile,
  NotFoundGlobalMobile,
  CategoryGlobalMobile,
  SearchGlobalMobile,
  SearchResultGlobalMobile,
  TicketGlobalMobile,
  MyGlobalMobile,
  ProductGlobalMobile,
  WithdrawalGlobalMobile,
  HelpGlobalMobile,
  PrivacyPolicyGlobalMobile,
  UsagePolicyGlobalMobile
};
