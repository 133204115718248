import dayjs from 'dayjs';
import ko from 'dayjs/locale/ko';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { isGlobal } from '../validations';

const initDayjs = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(isToday);
  dayjs.extend(weekOfYear);
  dayjs.extend(isBetween);
  if (!isGlobal()) {
    dayjs.locale(ko);
  }
  dayjs.tz.setDefault('Asia/Seoul');
};

const getDateAfterDays = (day = 1) => {
  return dayjs().tz().add(day, 'day').startOf('day').toDate();
};

const expireCookieFormat = (date: Date) => {
  return dayjs(date).tz().format('YYYY-MM-DD');
};

const getDayClass = (timestamp: Date | number) => {
  const day = dayjs(timestamp).tz().format('d');
  if (day === '6') return 'sat';
  if (day === '0') return 'sun';
  return;
};

const dateWithTimezone = (date: Date) => {
  const utcTimestamp = date.getTime() + date.getTimezoneOffset() * 60 * 1000;
  const timezoneDiff = 9 * 60 * 60 * 1000;
  return new Date(utcTimestamp + timezoneDiff);
};

export { initDayjs, getDateAfterDays, expireCookieFormat, getDayClass, dateWithTimezone };
