import { Dispatch, SetStateAction } from 'react';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import useLogin from '@tkl-packages/hooks/src/useLogin';
import { TeamBySubCategory } from '@tkl-packages/models';
import { Response } from '@tkl-packages/sdk';
import { GlobalView } from '@tkl-packages/sdk/src/utils';
import { getHomeTeam, getTeamsBySubCategoryIdApi } from '@tkl-packages/services/src/mapi';
import TKLink from '../../../atoms/TKLink';

const teamDisplayFilter = (team: TeamBySubCategory) => {
  if (GlobalView.isGlobal()) {
    return team.globalDisplayYn === 'Y';
  } else {
    return team.displayYn === 'Y';
  }
};

export const CategoryListLayerTeam = ({
  categoryId,
  subCategoryId,
  setActiveLayer
}: {
  categoryId?: number;
  subCategoryId: number;
  setActiveLayer: Dispatch<SetStateAction<boolean>>;
}) => {
  const { data: teams } = useQuery<Response<TeamBySubCategory[]>, Error, TeamBySubCategory[]>({
    queryKey: [QUERY_KEY.TEAMS, subCategoryId],
    queryFn: () => getTeamsBySubCategoryIdApi(`${subCategoryId}`),
    // TODO:II 테스트 후 수정 필요_QA 후 변경 예정
    staleTime: Infinity,
    enabled: !!subCategoryId,
    select: (data) => data.data,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  const { isLoggedIn } = useLogin();

  const { data: homeTeam } = useQuery({
    queryKey: [QUERY_KEY.HOME_TEAM, `${categoryId}`],
    queryFn: () => getHomeTeam(`${categoryId}`),
    // TODO:II 테스트 후 수정 필요_QA 후 변경 예정
    staleTime: Infinity,
    enabled: isLoggedIn && !!categoryId,
    select: (data) => data.data,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  return (
    <ul className="header_lnb_list">
      {teams &&
        teams.length > 0 &&
        teams.filter(teamDisplayFilter).map((team) => (
          <li onClick={() => setActiveLayer(false)} key={team.teamId} className="header_lnb_item">
            <TKLink
              to={`/sports/${categoryId}/${team.teamId}`}
              className={`header_lnb_link ${homeTeam && homeTeam.teamId === team.teamId && 'like'}`}
            >
              {team.teamName}
            </TKLink>
          </li>
        ))}
    </ul>
  );
};
