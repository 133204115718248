import { useMemo } from 'react';
import { BottomModalMobile } from '@tkl-packages/components/src/ticketlink/Modal';
import { APP_SCHEME, callAppScheme } from '@tkl-packages/constants/appScheme';
import { LOCALE, LANGUAGES } from '@tkl-packages/constants/locale';
import { getGlobalChannel, GlobalView } from '@tkl-packages/sdk/src/utils';
import { isAppWeb } from '@tkl-packages/sdk/src/validations';

interface props {
  onClose(): void;
}

const SelectLanguageModalMobile = ({ onClose }: props) => {
  const currentLocale = useMemo(() => getGlobalChannel(), []);

  const onClickLanguage = (locale: string) => {
    if (currentLocale === locale) return;

    const path = GlobalView.stripGlobalPath(window.location.pathname);
    if (locale === LOCALE.KO) {
      window.location.href = path;
    } else {
      isAppWeb()
        ? callAppScheme(APP_SCHEME.EXTERNAL_LINK, { url: `${window.location.origin}/global/${locale}${path}` })
        : window.open(`${window.location.origin}/global/${locale}${path}`, '_blank');
    }
  };

  return (
    <BottomModalMobile title="언어 선택" onClose={onClose} hasFooter={false}>
      <ul className="bottom_check_list">
        {LANGUAGES.map(({ code, name }) => (
          <li key={code} className="bottom_check_item">
            <span className="radio_item">
              <input
                type="radio"
                name="language"
                id="lang_01"
                className="input_radio"
                checked={code === currentLocale}
                readOnly
              />
              <label htmlFor="lang_01" className="label_radio" onClick={() => onClickLanguage(code)}>
                {name}
              </label>
            </span>
          </li>
        ))}
      </ul>
    </BottomModalMobile>
  );
};

export default SelectLanguageModalMobile;
