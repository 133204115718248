import { Navigate, Route, Routes } from 'react-router-dom';

const MyRouteGlobal = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to=".." />} />

      {/* TODO:II:GLOBAL_MOBILE */}
      {/* <Route
        index
        element={
          <NormalHeaderGlobal>
            <Channel mobile={<MyGlobalMobile />} />
          </NormalHeaderGlobal>
        }
      />
      <Route path="*" element={<WithLoginOutlet />}>
        <Route
          path="withdrawal"
          element={
            <NormalHeaderGlobal>
              <Channel mobile={<WithdrawalGlobalMobile />} />
            </NormalHeaderGlobal>
          }
        />

        <Route path="*" element={<Navigate to=".." />} />
      </Route> */}
    </Routes>
  );
};

export default MyRouteGlobal;
