import {
  HomeTeam,
  OffSeasonSettings,
  ScheduleRequest,
  TabInfo,
  TabInfoPayload,
  TeamBySubCategory,
  TeamNotice,
  TeamNoticeResponse
} from '@tkl-packages/models';
import { Response } from '@tkl-packages/sdk/src/axios/axios.type';
import { requests } from '@tkl-packages/sdk/src/axios/mapiInterface';

// common
const getCategoriesApi = (): Promise<Response> => requests.get('sports/categories');
const getReserveOpenNotification = (offSeasonSportsReserveOpenId: number): Promise<Response> =>
  requests.get(`sports/off-season/reserve-open-notification/subscription/${offSeasonSportsReserveOpenId}`);
const setReserveOpenNotification = (offSeasonSportsReserveOpenId: number): Promise<Response> =>
  requests.post(`sports/off-season/reserve-open-notification/subscription/${offSeasonSportsReserveOpenId}`, {});
const deleteReserveOpenNotification = (offSeasonSportsReserveOpenId: number): Promise<Response> =>
  requests.delete(`sports/off-season/reserve-open-notification/subscription/${offSeasonSportsReserveOpenId}`);
const getOffSeasonSettings = (categoryName: string): Promise<Response<OffSeasonSettings>> =>
  requests.get(`sports/off-season/${categoryName}`);
const getSportsEventsApi = (catergoryName: string): Promise<Response> =>
  requests.get(`sports/category/events/${catergoryName}`);

// pc
const getSportsMainBannersApi = (): Promise<Response> => requests.get('sports/banners/top');
const getDiscountCardBannerApi = (): Promise<Response> => requests.get('/sports/banner/card');
const getKboBannersApi = (): Promise<Response> => requests.get('sports/banners/kbo');
const getWingBannersApi = (): Promise<Response> => requests.get('sports/banners/wing');
const getReserveOpenApi = (categoryId: string): Promise<Response> =>
  requests.get(`sports/reserveOpen?categoryId=${categoryId}`);
const getReserveOpenCategoriesApi = (): Promise<Response> => requests.get('sports/openReserve/categories');
const getESportsBannersApi = (): Promise<Response> => requests.get('sports/banner/esports');

// mobile
const getHeroBannersApi = (): Promise<Response> => requests.get('sports/banners/big');
const getTeamBannersApi = (teamId: string): Promise<Response> => requests.get(`sports/banner/team/${teamId}`);
const getPopupBannersApi = (): Promise<Response> => requests.get('sports/banner/popup');
const getReserveBannersApi = (): Promise<Response> => requests.get('sports/banner/reserve');
const getSchedulesApi = (params?: Partial<ScheduleRequest>): Promise<Response> =>
  requests.get('sports/schedules', params);
const getTeamsApi = (categoryId: string): Promise<Response> => requests.get(`sports/category/${categoryId}/teams`);
const getTeamsBySubCategoryIdApi = (subCategoryId: string): Promise<Response<TeamBySubCategory[]>> =>
  requests.get(`sports/category/${subCategoryId}/teamsByCategoryId`);
const getTeamApi = (teamId: string): Promise<Response> => requests.get(`sports/team/${teamId}`);
const getFacilityTeamApi = (): Promise<Response> => requests.get(`sports/facility/team`);
const getEventsApi = (categoryId: string): Promise<Response> => requests.get(`sports/category/${categoryId}/events`);
const getFacilityEventsApi = (): Promise<Response> => requests.get('sports/facility/events');
const getEventApi = (
  eventId: string
): Promise<
  Response<{
    title?: string;
    eventContent?: string;
  }>
> => requests.get(`sports/category/event/${eventId}`);
const getTodayDateApi = (): Promise<Response> => requests.get('date/now');
const getTeamPopupBannerApi = (teamId: string): Promise<Response> => requests.get(`sports/banner/popup/team/${teamId}`);
const getTeamPopupNoticeApi = (teamId: string): Promise<Response> => requests.get(`sports/notice/popup/team/${teamId}`);
const getTeamNoticeFacility = (teamId: string): Promise<Response<TeamNotice[]>> =>
  requests.get(`sports/notice/team/${teamId}`);
const getTeamNoticeApi = ({
  teamId,
  page,
  itemPerPage
}: {
  teamId: string;
  page: number;
  itemPerPage: number;
}): Promise<Response<TeamNoticeResponse>> =>
  requests.get(`sports/notice/team/${teamId}/getPageList?page=${page}&itemPerPage=${itemPerPage}`);
const getTeamTopNotice = (teamId: string): Promise<Response<TeamNotice[]>> =>
  requests.get(`sports/notice/team/${teamId}/getTopNotice`);
const getTeamGuideApi = (teamId: string): Promise<Response> => requests.get(`sports/team/${teamId}/information`);
const getTeamDiscountApi = (teamId: string): Promise<Response> =>
  requests.get(`sports/team/${teamId}/information/price`);
const getTeamSeatMapApi = (teamId: string): Promise<Response> => requests.get(`sports/team/${teamId}/information/seat`);
const checkReservableApi = (): Promise<Response> => requests.get('sports/sports/reservable');
const checkScheduleApi = (scheduleId: string): Promise<Response> => requests.get('sports/schedule', { scheduleId });
const getHomeTeam = (categoryId: string): Promise<Response<HomeTeam>> =>
  requests.get(`sports/category/${categoryId}/getHomeTeam`);
const setHomeTeam = (categoryId: string, teamId: string, state: boolean): Promise<Response> => {
  const mode = state ? 'D' : 'I';
  return requests.post('sports/category/setHomeTeam', { categoryId, teamId, mode });
};
const getSportsTabInfo = (categoryId: string, teamId: string): Promise<Response<{ teamInfoList: TabInfo[] }>> =>
  requests.get(`sports/team/${categoryId}/${teamId}/getTabInfo`);
const getSportsTabInfoDetail = (payload: TabInfoPayload): Promise<Response<string>> =>
  requests.get(`sports/team/getTabInfoDetail`, payload);

export {
  getESportsBannersApi,
  getSportsEventsApi,
  getSportsMainBannersApi,
  getDiscountCardBannerApi,
  getReserveOpenCategoriesApi,
  getKboBannersApi,
  getWingBannersApi,
  getReserveOpenApi,
  getHeroBannersApi,
  getTeamBannersApi,
  getPopupBannersApi,
  getCategoriesApi,
  getSchedulesApi,
  getTeamsApi,
  getTeamsBySubCategoryIdApi,
  getTeamApi,
  getFacilityTeamApi,
  getEventsApi,
  getEventApi,
  getFacilityEventsApi,
  getTodayDateApi,
  getTeamPopupBannerApi,
  getTeamPopupNoticeApi,
  getTeamNoticeApi,
  getTeamGuideApi,
  getTeamDiscountApi,
  getTeamSeatMapApi,
  checkReservableApi,
  checkScheduleApi,
  getReserveBannersApi,
  getReserveOpenNotification,
  setReserveOpenNotification,
  deleteReserveOpenNotification,
  getOffSeasonSettings,
  getHomeTeam,
  setHomeTeam,
  getSportsTabInfo,
  getSportsTabInfoDetail,
  getTeamTopNotice,
  getTeamNoticeFacility
};
