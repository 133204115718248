import { CATEGORY } from '@tkl-packages/constants/category';
import { GNB_EXPOSURE_TYPE } from '@tkl-packages/constants/common';
import { SPORTS_SEASON_SETTING_TYPE } from '@tkl-packages/constants/sports';
import { GnbBannerDateTime, GnbCategory, GnbSubBanner } from '@tkl-packages/models';
import dayjs, { Dayjs } from 'dayjs';

/**
 * 카테고리 경로를 관리합니다.
 */
export const makeCategoryPath = (eUpperCategory: number, categoryId: number) => {
  const ONE_DEPTH_CATEGORIES = [CATEGORY.PERFORMANCE, CATEGORY.EXHIBITION, CATEGORY.SPORTS];
  const oneDepth = ONE_DEPTH_CATEGORIES.includes(categoryId) ? categoryId : eUpperCategory;
  switch (oneDepth) {
    case CATEGORY.PERFORMANCE:
      return '/' + 'performance' + '/' + categoryId;
    case CATEGORY.EXHIBITION:
      return '/' + 'exhibition' + '/' + categoryId;
    case CATEGORY.SPORTS:
      return '/' + 'sports' + '/' + categoryId;
    default:
      if (eUpperCategory === 0)
        console.warn('makeCategoryPath: undefined 1depth.', oneDepth, eUpperCategory, categoryId);
      else console.error('makeCategoryPath', oneDepth, eUpperCategory, categoryId);
      return '404';
  }
};

/**
 * 시즌 여부를 확인 후, 필요한 클래스네임을 반환합니다.
 */
export const seasonClassName = ({ seasonSettingType, gnbExposureTypeCode }: GnbCategory) => {
  if (gnbExposureTypeCode === GNB_EXPOSURE_TYPE.EVENT || seasonSettingType === SPORTS_SEASON_SETTING_TYPE.SEASON)
    return `header_gnb_link_season`;
  return '';
};

/**
 * GNB 배너의 유효성 검사를 수행하는 함수입니다.
 */
export const isValidBanner = ({
  bannerExposureYn,
  exposureStartDatetime,
  exposureEndDatetime
}: Pick<GnbSubBanner, 'bannerExposureYn' | 'exposureStartDatetime' | 'exposureEndDatetime'>) => {
  const startDateTime = dayjs()
    .tz()
    .year(exposureStartDatetime.date.year)
    .month(exposureStartDatetime.date.month - 1)
    .date(exposureStartDatetime.date.day)
    .hour(exposureStartDatetime.time.hour)
    .minute(exposureStartDatetime.time.minute)
    .second(exposureStartDatetime.time.second);
  const endDateTime = dayjs()
    .tz()
    .year(exposureEndDatetime.date.year)
    .month(exposureEndDatetime.date.month - 1)
    .date(exposureEndDatetime.date.day)
    .hour(exposureEndDatetime.time.hour)
    .minute(exposureEndDatetime.time.minute)
    .second(exposureEndDatetime.time.second);

  return bannerExposureYn === 'Y' && dayjs().isBetween(startDateTime, endDateTime);
};

/**
 * 이벤트 배너의 date를 Gnb 배너의 date 포멧으로 변경
 */
export const gnbBannerDateTime = (date: Dayjs): GnbBannerDateTime => {
  return {
    date: {
      year: date.year(),
      month: date.month() + 1,
      day: date.date()
    },
    time: {
      hour: date.hour(),
      minute: date.minute(),
      second: date.second(),
      nano: date.millisecond() * 1000000
    }
  };
};
