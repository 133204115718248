import { Dispatch, memo, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { GnbCategory } from '@tkl-packages/models';
import { GlobalView } from '@tkl-packages/sdk/src/utils';
import { CategoryListLayerBanner } from './CategoryListLayerBanner';
import { CategoryListLayerTeam } from './CategoryListLayerTeam';
import { isValidBanner } from './utils';

interface CategoryListLayerProps {
  isActive: boolean;
  category?: GnbCategory;
  isOffSeason?: boolean;
  setActiveLayer: Dispatch<SetStateAction<boolean>>;
}

/**
 * CategoryListLayer
 * @description GNB > CategoryListItem 마우스 클릭(또는 오버)시 노출되는 레이어 컴포넌트
 */
export const CategoryListLayer = memo(({ isActive, category, isOffSeason, setActiveLayer }: CategoryListLayerProps) => {
  const categoryId = category?.categoryId;

  const { t } = useTranslation();

  const isCategoryGnbNotEmpty = category && category.subGnbs && category.subGnbs.length > 0;

  const isTeamShow = isCategoryGnbNotEmpty && !isOffSeason;
  const banners = GlobalView.isGlobal()
    ? category?.globalExhibitionCategoryBanners?.filter(isValidBanner)
    : category?.exhibitionCategoryBanners?.filter(isValidBanner);

  if (isOffSeason) {
    return (
      <div className={`header_lnb ${isActive ? 'is-active' : ''}`}>
        {banners && banners.length > 0 ? (
          <CategoryListLayerBanner banners={banners} />
        ) : (
          <p className="lnb_no_list">{t('예매 가능한 경기가 없습니다. 다음 시즌을 기다려주세요!')}</p>
        )}
      </div>
    );
  }

  return (
    <div className={`header_lnb ${isActive ? 'is-active' : ''}`}>
      {isTeamShow &&
        category.subGnbs.map((gnb) => {
          if (gnb?.categoryNameEng?.includes('Event')) {
            return null;
          }

          return (
            <div key={gnb.categoryId} className="lnb_group">
              <strong className="tit_group">
                {GlobalView.applyGlobalField({
                  ko: gnb.categoryNameKor,
                  en: gnb.categoryNameEng,
                  ja: gnb.categoryNameJa,
                  zh: gnb.categoryNameZh
                })}
              </strong>
              <CategoryListLayerTeam
                setActiveLayer={setActiveLayer}
                categoryId={categoryId}
                subCategoryId={gnb.categoryId}
              />
            </div>
          );
        })}

      {banners && banners.length > 0 && <CategoryListLayerBanner banners={banners} />}
    </div>
  );
});

CategoryListLayer.displayName = 'CategoryListLayer';
