import { CSSProperties, FC, PropsWithChildren, ReactNode, useState } from 'react';
import { FooterGlobalMobile, FooterMobile } from '@tkl-packages/components/src/ticketlink/Footer';
import { HeaderMobile } from '@tkl-packages/components/src/ticketlink/Header';
import { Modals } from '@tkl-packages/components/src/ticketlink/Modal';
import { Navigation, ToolBarUtil } from '@tkl-packages/components/src/ticketlink/Navigation';
import { GlobalView } from '@tkl-packages/sdk/src/utils';
import { SelectLanguageModalMobile } from '../Language';
import NavigationGlobal from '../Navigation/Navigation.global';
import { getPoductToolbarUtilStyle } from '../Navigation/getPoductToolbarUtilStyle';
import ProductShareModalMobile from '../Share/Product/ProductShareModal.mobile';

type LayoutProps = {
  title?: string;

  onClickBackBtn?(): void;

  onClickCloseBtn?(): void;

  useTransparency?: boolean;
  useTransparencyTitle?: boolean;
  useShareBtn?: boolean;
  useLanguageBtn?: boolean;
  header?: ReactNode;
  navigation?: ReactNode;
  footer?: ReactNode;
  style?: CSSProperties;
};

const LayoutMobile: FC<PropsWithChildren<LayoutProps>> = (props) => {
  const {
    title,
    useTransparency = false,
    useTransparencyTitle = false,
    useShareBtn = false,
    useLanguageBtn = false,
    onClickBackBtn,
    onClickCloseBtn,
    header,
    navigation,
    footer,
    children,
    style
  } = props;
  const [isShowShare, setIsShowShare] = useState(false);
  const [isShowSelectLanguage, setIsSelectLanguage] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const isGlobal = GlobalView.isGlobal();
  const NavigationSelected = isGlobal ? NavigationGlobal : Navigation;
  const FooterSelected = isGlobal ? FooterGlobalMobile : FooterMobile;

  return (
    <>
      <div className="m_wrap" style={style}>
        {header || (
          <HeaderMobile
            useShareBtn={useShareBtn}
            useTransparency={useTransparency}
            useTransparencyTitle={useTransparencyTitle}
            useLanguageBtn={useLanguageBtn}
            title={title}
            onClickBackBtn={onClickBackBtn}
            onClickCloseBtn={onClickCloseBtn}
            onClickShareBtn={() => setIsShowShare(true)}
            onClickLanguageBtn={() => setIsSelectLanguage(true)}
          />
        )}
        {navigation || (
          <NavigationSelected isHide={isHide}>
            <ToolBarUtil isHide={isHide} setIsHide={setIsHide} style={getPoductToolbarUtilStyle()} />
          </NavigationSelected>
        )}
        {children}
        {footer || <FooterSelected />}
      </div>
      <Modals />
      {isShowShare && <ProductShareModalMobile onClose={() => setIsShowShare(false)} />}
      {isShowSelectLanguage && <SelectLanguageModalMobile onClose={() => setIsSelectLanguage(false)} />}
    </>
  );
};

export default LayoutMobile;
