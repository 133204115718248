import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { LoginContext } from '@tkl-apps/ticketlink/src/components/Context/Login';
import { SearchBox } from '@tkl-apps/ticketlink/src/components/Search';
import TKLink from '@tkl-packages/components/src/atoms/TKLink';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { LANGUAGES } from '@tkl-packages/constants/locale';
import useOutsideClick from '@tkl-packages/hooks/src/useOutsideClick';
import { GlobalView } from '@tkl-packages/sdk/src/utils';
import { getGlobalGnbBannerApi } from '@tkl-packages/services/src/ad/adApi';
import HeaderGnbGlobal from './Gnb/HeaderGnb.global';

/**
 * 해당 컴포넌트는 JSP Page에서 라이브러리로 재사용합니다.
 * - PC 버전 ( global )
 */
const HeaderGlobal = () => {
  const { t } = useTranslation();

  // locale 선택 컴포넌트
  const globalLocale = GlobalView.getGlobalLocale();
  const currentLocale = LANGUAGES.filter((item) => item.code === globalLocale).pop() ?? LANGUAGES[0];
  const [isShowLanguage, setIsShowLanguage] = useState(false);
  const { ref: languageRef } = useOutsideClick(() => {
    setIsShowLanguage(false);
  });

  const { data: adData } = useQuery({
    queryKey: [QUERY_KEY.GLOBAL_GNB_AD_BANNER],
    queryFn: getGlobalGnbBannerApi,
    // TODO:II 테스트 후 수정 필요_QA 후 변경 예정
    staleTime: Infinity
  });

  // 로그인 버튼
  const { isLoggedIn, isPending, goLogin, needLogin } = useContext(LoginContext);

  const onClickReserveConfirm = needLogin(() => {
    const pcWebPath = GlobalView.replacePCWebServerPath('/my/reserve/list');
    const myReserveList = GlobalView.applyPath(pcWebPath);
    window.location.href = myReserveList;
  });

  const onClickJoin = () => {
    const pcWebPath = GlobalView.replacePCWebServerPath('/auth/join');
    const joinPath = GlobalView.applyPath(pcWebPath);
    window.open(
      joinPath + `.nhn?nextURL=${document.URL}`,
      t('회원가입'),
      `toolbar=no, channelmode=no, location=no, directories=no, menubar=no, scrollbars=yes, width=630, height=550`
    );
  };

  return (
    <header className="common_header">
      <div className="header_inner">
        <h1 className="header_title">
          <TKLink to="/home" className="header_title_link" onClick={() => window.scrollTo(0, 0)}>
            <span className="blind">ticketlink</span>
          </TKLink>
        </h1>

        <SearchBox />

        {adData && (
          <div className="header_banner">
            <a href={adData.click} target="_blank" className="header_banner_link" rel="noreferrer">
              <img src={adData.image} className="header_banner_img" alt="" />
            </a>
          </div>
        )}

        <div className="header_util">
          <ul className="header_util_list">
            {!isPending && !isLoggedIn && (
              <li className="header_util_item">
                <a className="header_util_link login" onClick={goLogin}>
                  {t('로그인')}
                </a>
              </li>
            )}
            {!isPending && isLoggedIn && (
              <li className="header_util_item">
                <TKLink
                  useNative
                  to={GlobalView.replacePCWebServerPath('/auth/logout')}
                  className="header_util_link logout"
                >
                  {t('로그아웃')}
                </TKLink>
              </li>
            )}
            {!isPending && !isLoggedIn && (
              <li className="header_util_item">
                <a className="header_util_link signup" onClick={onClickJoin}>
                  {t('회원 가입')}
                </a>
              </li>
            )}
            <li className="header_util_item">
              <a className="header_util_link my" onClick={onClickReserveConfirm}>
                {t('마이')}
              </a>
            </li>
            <li className="header_util_item">
              <TKLink useNative to="/helpRenewal/main" className="header_util_link help">
                {t('고객센터_메인페이지')}
              </TKLink>
            </li>
            <li className="header_util_item">
              <button
                type="button"
                className="btn_dropdown"
                aria-expanded={isShowLanguage}
                onClick={() => setIsShowLanguage(true)}
              >
                <span className={`ico_lang ${currentLocale.iconClassName}`} />
                {' ' + currentLocale.localizedName}
              </button>
              <div className="header_dropdown_content" ref={languageRef}>
                <ul className="header_language_list">
                  {LANGUAGES.map((LANGUAGE) => {
                    return (
                      <li key={LANGUAGE.code} className="header_language_item">
                        <a
                          href={LANGUAGE.code === 'ko' ? '/' : GlobalView.getChangedGlobalLocalePath(LANGUAGE.code)}
                          className="header_language_link"
                          aria-selected={currentLocale.code === LANGUAGE.code}
                        >
                          <span className={`ico_lang ${LANGUAGE.iconClassName}`} />
                          {' ' + LANGUAGE.localizedName}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <HeaderGnbGlobal />
    </header>
  );
};

export default HeaderGlobal;
