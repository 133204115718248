import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useExhibitionCategories } from '@tkl-apps/ticketlink/src/stores/categoryStore/categoryStore';
import TKLink from '@tkl-packages/components/src/atoms/TKLink';
import { SESSION_KEY } from '@tkl-packages/constants/user';
import { useSubHeaderType } from '@tkl-packages/hooks/src/useSubHeaderType';
import { Navigation } from 'swiper';
import { Swiper as SwiperInstance } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CategoryListItem } from './CategoryListItem/CategoryListItem';

const useGnbGlobalMenu = () => {
  const { data: categoriesResp, isPending } = useExhibitionCategories();
  // - 1뎁스 글로벌 노출이면, 2뎁스 무시.
  const uiCategoriesForGnb = useMemo(() => {
    if (categoriesResp === undefined) return [];
    const sorted = [...categoriesResp].sort((a, b) => a.categoryOrder - b.categoryOrder);
    sorted.forEach((category) => {
      category.subGnbs = [...category.subGnbs].sort((a, b) => a.categoryOrder - b.categoryOrder);
    });

    const flatUiCategories = sorted
      .map((v) => {
        // 1뎁스 노출이면 1뎁스 사용
        if (v.globalExposureYn === 'Y') return v;
        // 1뎁스 비노출이면 2뎁스 사용
        return v.subGnbs;
      })
      .flat()
      .filter((v) => v.globalExposureYn === 'Y');

    return flatUiCategories;
  }, [categoriesResp]);

  return { uiCategoriesForGnb, isPending };
};
const HeaderGnbGlobal = () => {
  const { t } = useTranslation();

  const { uiCategoriesForGnb, isPending } = useGnbGlobalMenu();

  const { includesUrlPath } = useSubHeaderType();

  const swiperIns = useRef<SwiperInstance | null>(null);
  // - 홈 URL 처리
  useEffect(() => {
    if (swiperIns.current && includesUrlPath('/home')) swiperIns.current.slideTo(0, 1500);
  }, [includesUrlPath]);

  // - 스와이퍼 JSP 인덱스 처리: 저장
  const onSnapIndexChange = (swiper: SwiperInstance) => {
    sessionStorage.setItem(SESSION_KEY.GLOBAL_GNB_SWIPER_INDEX, swiper.snapIndex.toString());
  };
  // - 스와이퍼 JSP 인덱스 처리: 초기 생성 시, 이전 인덱스 사용
  const getInitialSlide = () => {
    return parseInt(sessionStorage.getItem(SESSION_KEY.GLOBAL_GNB_SWIPER_INDEX) ?? '0');
  };

  return (
    <div className="header_gnb">
      <div className="header_gnb_inner">
        {/**
         * @note for JSP: 카테고리 정보로 아이템들을 만들고, Swiper Instance가 생성되어야, 스와이퍼가 동적 아이템들의 길이를 0으로 보고 initialSlide를 줄이는 것을 방지할 수 있습니다.
         */}
        {!isPending && (
          <Swiper
            onSwiper={(swiper) => (swiperIns.current = swiper)}
            tag="nav"
            wrapperTag="ul"
            className="header_gnb_area swiper-container swiper-container-initialized swiper-container-horizontal"
            direction="horizontal"
            slidesPerView="auto"
            threshold={10}
            modules={[Navigation]}
            navigation={{
              prevEl: '.header_gnb_nav.swiper-button-prev',
              nextEl: '.header_gnb_nav.swiper-button-next'
            }}
            onSnapIndexChange={onSnapIndexChange}
            initialSlide={getInitialSlide()}
          >
            <h2 className="blind">{t('Main navigation')}</h2>
            <ul className="header_gnb_list swiper-wrapper">
              <SwiperSlide className="header_gnb_item">
                <TKLink
                  to="/home"
                  className="header_gnb_link"
                  aria-current={includesUrlPath('/home')}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {t('홈')}
                </TKLink>
              </SwiperSlide>
              {uiCategoriesForGnb.map((category) => (
                <SwiperSlide key={category.categoryId} className="header_gnb_item">
                  <CategoryListItem category={category} />
                </SwiperSlide>
              ))}
              <SwiperSlide className="header_gnb_item">
                <TKLink
                  useNative
                  to="/linkon/main"
                  className="header_gnb_link"
                  aria-current={includesUrlPath('/linkon')}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  LINKON
                </TKLink>
              </SwiperSlide>
              <SwiperSlide className="header_gnb_item">
                <TKLink
                  to="/local"
                  className="header_gnb_link"
                  aria-current={includesUrlPath('/local')}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {t('지역')}
                </TKLink>
              </SwiperSlide>
            </ul>

            <button className="header_gnb_nav swiper-button-prev">
              <span className="common_icon"></span>
              <span className="blind">{t('이전')}</span>
            </button>
            <button className="header_gnb_nav swiper-button-next">
              <span className="common_icon"></span>
              <span className="blind">{t('다음')}</span>
            </button>
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default HeaderGnbGlobal;
