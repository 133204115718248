import { useRef, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { GlobalView } from '@tkl-packages/sdk/src/utils';
import { isMobile } from '@tkl-packages/sdk/src/validations';
import debounce from 'lodash/debounce';

interface props {
  query?: string;
  path: string;
}

const useSearch = ({ query, path }: props) => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState(query || '');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isShowAutoComplete, setIsShowAutoComplete] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const setDebounceSearchKeyword = useRef(debounce((k) => setSearchKeyword(k), 500)).current;

  const onChangeKeyword: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setKeyword(e.target.value);
    setDebounceSearchKeyword(e.target.value);
    if (!isShowAutoComplete) {
      setIsShowAutoComplete(true);
    }
  };

  const onDeleteKeyword = () => {
    setKeyword('');
    setSearchKeyword('');
    inputRef.current?.focus();
  };

  const onSearch = () => {
    if (isShowAutoComplete) {
      setIsShowAutoComplete(false);
    }

    if (isMobile()) {
      // TODO:II:GLOBAL_MOBILE, 글로벌 모바일에서는 리액트 이동이지만, `글로벌 PC 버전만` 출시 한 경우 (모바일 디바이스에서도) JSP(검색결과 페이지)로 이동하기 때문에 새로고침 필요함.
      if (GlobalView.isGlobal()) {
        window.location.href = `${path}?${createSearchParams({ query: keyword })}`;
      } else {
        navigate({
          pathname: path,
          search: `?${createSearchParams({ query: keyword })}`
        });
      }
    } else {
      window.location.href = `${path}?${createSearchParams({ query: keyword })}`;
    }
  };

  return {
    keyword,
    searchKeyword,
    isShowAutoComplete,
    setIsShowAutoComplete,
    inputRef,
    onChangeKeyword,
    onDeleteKeyword,
    onSearch
  };
};

export default useSearch;
