import { ChangeEvent, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import useConditionalCSS from '@tkl-apps/ticketlink/src/hooks/header/useConditionalCSS';
import { RenewalTicketMobile4Email } from '@tkl-apps/ticketlink/src/pages/mobile';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import { LayoutGlobal } from '@tkl-packages/components/src/ticketlink/Layout';
import { PageLoading } from '@tkl-packages/components/src/ticketlink/Loading';
import NormalHeaderGlobal from '../components/HTMLHeader/NormalHeader.global';
import { NotFoundGlobalMobile } from '../pages/global/mobile';
import { NotFoundGlobal } from '../pages/global/pc';
import CategoryRouteGlobal from './globalPaths/Category';
import ExhibitionRouteGlobal from './globalPaths/Exhibition';
import HelpRouteGlobal from './globalPaths/Help';
import HomeRouteGlobal from './globalPaths/Home';
import LocalRouteGlobal from './globalPaths/Local';
import LoginRouteGlobal from './globalPaths/Login';
import MyRouteGlobal from './globalPaths/My';
import PerformanceRouteGlobal from './globalPaths/Performance';
import PolicyRouteGlobal from './globalPaths/Policy';
import ProductRouteGlobal from './globalPaths/Product';
import SearchRouteGlobal from './globalPaths/Search';
import SportsRouteGlobal from './globalPaths/Sports';
import TicketRouteGlobal from './globalPaths/Ticket';

export const useTranslationFromUrl = () => {
  const { t } = useTranslation();
  const { locale: lng } = useParams<{ locale: string }>();

  return (text: string) => t(text, { lng });
};

export const useLanguageChange = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  return async (e: ChangeEvent<HTMLSelectElement>) => {
    await i18n.changeLanguage(e.target.value);

    if (e.target.value === 'ko') {
      navigate(`/home`);
    }
    navigate(`/global/${e.target.value}/home`);
  };
};

const GlobalOutlet = () => {
  // TODO:II:GLOBAL_MOBILE isPc(), 글로벌 모바일 출시 전에는 true 고정
  const activeGlobalPcCss = true && !location.pathname.includes('/mobile/ticket');
  useConditionalCSS({
    href: `${process.env.CSS_PC_URL_GLOBAL}/ticketlink_renewal.css`,
    isActive: activeGlobalPcCss
  });

  return (
    <>
      <Helmet>
        <title>TicketLink</title>
      </Helmet>
      {activeGlobalPcCss ? (
        <LayoutGlobal>
          <Outlet />
        </LayoutGlobal>
      ) : (
        <Outlet />
      )}
    </>
  );
};

const LocaleOutlet = () => {
  const { locale } = useParams<{ locale: string }>();

  if (locale === 'ko') {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

const GlobalRoute = () => {
  return (
    <Suspense fallback={<PageLoading useBackground={false} />}>
      <Routes>
        <Route path="*" element={<GlobalOutlet />}>
          <Route index element={<Navigate to="en" />} />
          <Route path=":locale" element={<LocaleOutlet />}>
            <Route index element={<Navigate to="home" />} />

            {/* 홈 */}
            <Route path="home/*" element={<HomeRouteGlobal />} />

            {/* 모바일 탭바 > 카테고리 페이지 */}
            <Route path="category" element={<CategoryRouteGlobal />} />
            {/* 모바일 탭바 > 검색 페이지 */}
            <Route path="search/*" element={<SearchRouteGlobal />} />
            {/* 모바일 탭바 > 티켓 페이지 */}
            <Route path="ticket" element={<TicketRouteGlobal />} />
            {/* 모바일 탭바 > 마이 페이지 */}
            <Route path="my/*" element={<MyRouteGlobal />} />

            {/* 스포츠 */}
            <Route path="sports/*" element={<SportsRouteGlobal />} />
            {/* 공연 */}
            <Route
              path="performance/*"
              element={
                <NormalHeaderGlobal>
                  <PerformanceRouteGlobal />
                </NormalHeaderGlobal>
              }
            />
            {/* 전시 */}
            <Route
              path="exhibition/*"
              element={
                <NormalHeaderGlobal>
                  <ExhibitionRouteGlobal />
                </NormalHeaderGlobal>
              }
            />
            {/* 지역별, 범위 포함 */}
            <Route
              path="local/*"
              element={
                <NormalHeaderGlobal>
                  <LocalRouteGlobal />
                </NormalHeaderGlobal>
              }
            />

            {/* linkon, 리액트 아님. 범위 포함. 리액트 미변경*/}
            {/* JSP */}

            {/* 상품 상세 */}
            <Route path="product/*" element={<ProductRouteGlobal />} />

            {/* 기획전 1차 범위 제외 */}
            {/* <Route path="planning-event/*" element={<PlanningEventRouteGlobal />} /> */}

            {/* 고객센터, 공지사항 */}
            <Route path="help/*" element={<HelpRouteGlobal />} />

            {/* 이용약관, 개인정보 처리방침 */}
            <Route path="policy/*" element={<PolicyRouteGlobal />} />

            {/* 광고 안내 */}
            {/* <Route path="advertisement" element={<DummyGlobalRoute />} /> */}

            {/* 국내는 하위에 FacilityMemberCard, RenewalTicketMobile4Sns..*/}
            {/* <Route path="mobile/*" element={<DummyGlobalRoute />} /> */}
            {/* 2024-10-22 범위 미포함기존 코드에 남아있어서 남겨둠. */}
            <Route
              path="mobile/ticket"
              element={
                <NormalHeaderGlobal>
                  <Channel mobile={<RenewalTicketMobile4Email />} />
                </NormalHeaderGlobal>
              }
            />

            {/* 범위 미 포함 */}
            {/* <Route path="ranking" element={<DummyGlobalRoute />} /> */}
            {/* <Route path="bugs" element={<DummyGlobalRoute />} /> */}
            {/* <Route path="plan/*" element={<DummyGlobalRoute />} /> */}
            {/* <Route path="app/*" element={<DummyGlobalRoute />} /> */}

            {/* 모바일 로그인: App, Web에 따라 페이코 로그인 절차 다름.*/}
            <Route path="login" element={<LoginRouteGlobal />} />

            <Route
              path="404"
              element={
                <NormalHeaderGlobal>
                  <Channel pc={<NotFoundGlobal />} mobile={<NotFoundGlobalMobile />} />
                </NormalHeaderGlobal>
              }
            />
            <Route path="*" element={<Navigate to=".." />} />
          </Route>
          <Route path="*" element={<Navigate to=".." />} />
        </Route>
        <Route path="*" element={<Navigate to=".." />} />
      </Routes>
    </Suspense>
  );
};

export default GlobalRoute;
