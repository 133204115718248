import { Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeader from '../../components/HTMLHeader/NormalHeader';
import PlanningHeader from '../../components/HTMLHeader/PlanningHeader';
import SportsHeader from '../../components/HTMLHeader/SportsHeader';
import { FacilityTeamMain } from '../../pages/facility';
import {
  PlanningMobile,
  SportsEventMobile,
  SportsMainMobile,
  SportsOffSeasonMobile,
  SportsTeamMainMobile
} from '../../pages/mobile';
import { Planning, SportsMain, SportsOffSeason, SportsTeam } from '../../pages/pc';

const SportsRoute = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader>
            <Channel pc={<SportsMain />} mobile={<SportsMainMobile />} />
          </NormalHeader>
        }
      />
      <Route path=":categoryId/*">
        <Route
          index
          element={
            <NormalHeader>
              <Channel mobile={<SportsMainMobile />} />
            </NormalHeader>
          }
        />
        <Route
          path="off-season"
          element={
            <SportsHeader>
              <Channel pc={<SportsOffSeason />} mobile={<SportsOffSeasonMobile />} />
            </SportsHeader>
          }
        />
        <Route
          path="planning-event/:planningEventId"
          element={
            <PlanningHeader>
              <Channel pc={<Planning />} mobile={<PlanningMobile />} />
            </PlanningHeader>
          }
        />
        <Route
          path="planning-event/:planningEventId/preview/:previewKey"
          element={
            <PlanningHeader>
              <Channel pc={<Planning />} mobile={<PlanningMobile />} />
            </PlanningHeader>
          }
        />
        <Route
          path=":teamId"
          element={
            <SportsHeader>
              <Channel pc={<SportsTeam />} facility={<FacilityTeamMain />} mobile={<SportsTeamMainMobile />} />
            </SportsHeader>
          }
        />
      </Route>
      <Route
        path="event/:eventId"
        element={
          <NormalHeader>
            <Channel mobile={<SportsEventMobile />} />
          </NormalHeader>
        }
      />
    </Routes>
  );
};

export default SportsRoute;
