import { MouseEventHandler, PropsWithChildren, useCallback } from 'react';
import { Link, LinkProps, useParams } from 'react-router-dom';
import { BASE_APP_SCHEME_URL } from '@tkl-packages/constants/appScheme';
import { isAppWeb } from '@tkl-packages/sdk/src/validations';
import { isLibraryMode } from '@tkl-packages/sdk/src/vite';

interface TKLinkProps extends LinkProps {
  useNative?: boolean;
  to: string;
}

export const useTkGlobalPath = () => {
  const { locale } = useParams<{ locale?: string }>();

  return (to: string) => {
    let destinationUrl = '';

    if (locale && locale !== 'ko') {
      destinationUrl = `/global/${locale}`;
    }

    if (isAppWeb() && to.startsWith(BASE_APP_SCHEME_URL)) {
      return `${destinationUrl}${to}`;
    }

    if (to.startsWith('http')) {
      return `${to}`;
    }

    if (to.startsWith('/')) {
      return `${destinationUrl}${to}`;
    }

    return `${destinationUrl}/${to}`;
  };
};

const TKLink = ({
  id,
  to,
  className,
  children,
  onClick,
  useNative = false,
  ...restProps
}: PropsWithChildren<TKLinkProps>) => {
  const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      if (onClick) {
        /**
         * @note `<a>` 및 `<Link>`는 onClick과 to를 모두 실행합니다.
         */
        // e.preventDefault();
        onClick(e);
      }
    },
    [onClick]
  );
  const calc = useTkGlobalPath();

  /**
   * JSP 페이지에서 React Lib Header로 이동 시,
   * <Link/>를 사용하면, 헤더, 푸터만 반응한다.
   * 서버에 요청하기 위해, <a>를 사용한다.
   */
  if (isLibraryMode()) {
    useNative = true;
  }

  return (
    <>
      {useNative ? (
        <a {...restProps} href={calc(to)} className={className} onClick={handleClick} id={id} data-testid={id}>
          {children}
        </a>
      ) : (
        <Link {...restProps} to={calc(to)} className={className} onClick={handleClick} id={id} data-testid={id}>
          {children}
        </Link>
      )}
    </>
  );
};

export default TKLink;
