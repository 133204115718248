import BASEBALL_SEASON from '@tkl-packages/assets/sports_baseball.png';
import BASEBALL_OFF_SEASON from '@tkl-packages/assets/sports_baseball_disabled.png';
import BASEBALL_OFF_SEASON_MOBILE from '@tkl-packages/assets/sports_baseball_disabled_mobile.png';
import BASEBALL_SEASON_MOBILE from '@tkl-packages/assets/sports_baseball_mobile.png';
import BASKETBALL_SEASON from '@tkl-packages/assets/sports_basketball.png';
import BASKETBALL_OFF_SEASON from '@tkl-packages/assets/sports_basketball_disabled.png';
import BASKETBALL_OFF_SEASON_MOBILE from '@tkl-packages/assets/sports_basketball_disabled_mobile.png';
import BASKETBALL_SEASON_MOBILE from '@tkl-packages/assets/sports_basketball_mobile.png';
import ESPORTS_SEASON from '@tkl-packages/assets/sports_esports.png';
import ESPORTS_OFF_SEASON from '@tkl-packages/assets/sports_esports_disabled.png';
import ESPORTS_OFF_SEASON_MOBILE from '@tkl-packages/assets/sports_esports_disabled_mobile.png';
import ESPORTS_SEASON_MOBILE from '@tkl-packages/assets/sports_esports_mobile.png';
import ETC_SEASON from '@tkl-packages/assets/sports_etc.png';
import ETC_OFF_SEASON from '@tkl-packages/assets/sports_etc_disabled.png';
import ETC_OFF_SEASON_MOBILE from '@tkl-packages/assets/sports_etc_disabled_mobile.png';
import ETC_SEASON_MOBILE from '@tkl-packages/assets/sports_etc_mobile.png';
import FOOTBALL_SEASON from '@tkl-packages/assets/sports_football.png';
import FOOTBALL_OFF_SEASON from '@tkl-packages/assets/sports_football_disabled.png';
import FOOTBALL_OFF_SEASON_MOBILE from '@tkl-packages/assets/sports_football_disabled_mobile.png';
import FOOTBALL_SEASON_MOBILE from '@tkl-packages/assets/sports_football_mobile.png';
import HANDBALL_SEASON from '@tkl-packages/assets/sports_handball.png';
import HANDBALL_OFF_SEASON from '@tkl-packages/assets/sports_handball_disabled.png';
import HANDBALL_OFF_SEASON_MOBILE from '@tkl-packages/assets/sports_handball_disabled_mobile.png';
import HANDBALL_SEASON_MOBILE from '@tkl-packages/assets/sports_handball_mobile.png';
import ICE_HOCKEY_SEASON from '@tkl-packages/assets/sports_ice_hockey.png';
import ICE_HOCKEY_OFF_SEASON from '@tkl-packages/assets/sports_ice_hockey_disabled.png';
import ICE_HOCKEY_OFF_SEASON_MOBILE from '@tkl-packages/assets/sports_ice_hockey_disabled_mobile.png';
import ICE_HOCKEY_SEASON_MOBILE from '@tkl-packages/assets/sports_ice_hockey_mobile.png';
import TABLE_TENNIS_SEASON from '@tkl-packages/assets/sports_pingpong.png';
import TABLE_TENNIS_OFF_SEASON from '@tkl-packages/assets/sports_pingpong_disabled.png';
import TABLE_TENNIS_OFF_SEASON_MOBILE from '@tkl-packages/assets/sports_pingpong_disabled_mobile.png';
import TABLE_TENNIS_SEASON_MOBILE from '@tkl-packages/assets/sports_pingpong_mobile.png';
import VOLLEYBALL_SEASON from '@tkl-packages/assets/sports_volleyball.png';
import VOLLEYBALL_OFF_SEASON from '@tkl-packages/assets/sports_volleyball_disabled.png';
import VOLLEYBALL_OFF_SEASON_MOBILE from '@tkl-packages/assets/sports_volleyball_disabled_mobile.png';
import VOLLEYBALL_SEASON_MOBILE from '@tkl-packages/assets/sports_volleyball_mobile.png';

const SCHEDULE = {
  MAIN: 'MAIN',
  TEAM: 'TEAM'
} as const;

const SPORTS = {
  BASE_BALL: 137,
  BASKET_BALL: 139,
  SOCCER: 138,
  VOLLEY_BALL: 140,
  ICE_HOCKEY: 1032,
  TABLE_TENNIS: 1045,
  HAND_BALL: 1191,
  // beta id입니다.
  ESPORTS: 1262,
  ETC: 153
} as const;

const STATE = {
  ON_SALE: 'ON_SALE',
  BEFORE: 'BEFORE',
  END: 'END'
} as const;

const RESERVE_BANNER = {
  FIX: 'FIX',
  SCROLL: 'SCROLL'
};

const OFF_SEASON_TEMPLATE_TYPE = {
  BIG_BANNER: 'BIG_BANNER',
  TEMPLATE: 'TEMPLATE',
  NONE: 'NONE'
} as const;

const SPORTS_SEASON_SETTING_TYPE = {
  SEASON: 'SEASON',
  OFF_SEASON: 'OFF_SEASON',
  NONE: 'NONE'
} as const;

const SPORTS_ICON_INFO = {
  [SPORTS.BASE_BALL]: {
    code: 'baseball',
    name: '프로야구'
  },
  [SPORTS.BASKET_BALL]: {
    code: 'basketball',
    name: '프로농구'
  },
  [SPORTS.SOCCER]: {
    code: 'soccer',
    name: '프로축구'
  },
  [SPORTS.VOLLEY_BALL]: {
    code: 'volleyball',
    name: '프로배구'
  },
  [SPORTS.ICE_HOCKEY]: {
    code: 'etc',
    name: '아이스하키'
  },
  [SPORTS.TABLE_TENNIS]: {
    code: 'pingpong',
    name: '탁구'
  },
  [SPORTS.HAND_BALL]: {
    code: 'handball',
    name: '핸드볼'
  },
  [SPORTS.ESPORTS]: {
    code: 'esports',
    name: 'E스포츠'
  },
  [SPORTS.ETC]: {
    code: 'etc',
    name: '기타'
  }
} as const;

const SPORTS_SEASON_SETTING_TYPE_ICON = {
  [SPORTS.BASE_BALL]: {
    [SPORTS_SEASON_SETTING_TYPE.SEASON]: [BASEBALL_SEASON, BASEBALL_SEASON_MOBILE],
    [SPORTS_SEASON_SETTING_TYPE.OFF_SEASON]: [BASEBALL_OFF_SEASON, BASEBALL_OFF_SEASON_MOBILE],
    [SPORTS_SEASON_SETTING_TYPE.NONE]: [BASEBALL_OFF_SEASON, BASEBALL_OFF_SEASON_MOBILE]
  },
  [SPORTS.SOCCER]: {
    [SPORTS_SEASON_SETTING_TYPE.SEASON]: [FOOTBALL_SEASON, FOOTBALL_SEASON_MOBILE],
    [SPORTS_SEASON_SETTING_TYPE.OFF_SEASON]: [FOOTBALL_OFF_SEASON, FOOTBALL_OFF_SEASON_MOBILE],
    [SPORTS_SEASON_SETTING_TYPE.NONE]: [FOOTBALL_OFF_SEASON, FOOTBALL_OFF_SEASON_MOBILE]
  },
  [SPORTS.BASKET_BALL]: {
    [SPORTS_SEASON_SETTING_TYPE.SEASON]: [BASKETBALL_SEASON, BASKETBALL_SEASON_MOBILE],
    [SPORTS_SEASON_SETTING_TYPE.OFF_SEASON]: [BASKETBALL_OFF_SEASON, BASKETBALL_OFF_SEASON_MOBILE],
    [SPORTS_SEASON_SETTING_TYPE.NONE]: [BASKETBALL_OFF_SEASON, BASKETBALL_OFF_SEASON_MOBILE]
  },
  [SPORTS.VOLLEY_BALL]: {
    [SPORTS_SEASON_SETTING_TYPE.SEASON]: [VOLLEYBALL_SEASON, VOLLEYBALL_SEASON_MOBILE],
    [SPORTS_SEASON_SETTING_TYPE.OFF_SEASON]: [VOLLEYBALL_OFF_SEASON, VOLLEYBALL_OFF_SEASON_MOBILE],
    [SPORTS_SEASON_SETTING_TYPE.NONE]: [VOLLEYBALL_OFF_SEASON, VOLLEYBALL_OFF_SEASON_MOBILE]
  },
  [SPORTS.HAND_BALL]: {
    [SPORTS_SEASON_SETTING_TYPE.SEASON]: [HANDBALL_SEASON, HANDBALL_SEASON_MOBILE],
    [SPORTS_SEASON_SETTING_TYPE.OFF_SEASON]: [HANDBALL_OFF_SEASON, HANDBALL_OFF_SEASON_MOBILE],
    [SPORTS_SEASON_SETTING_TYPE.NONE]: [HANDBALL_OFF_SEASON, HANDBALL_OFF_SEASON_MOBILE]
  },
  [SPORTS.TABLE_TENNIS]: {
    [SPORTS_SEASON_SETTING_TYPE.SEASON]: [TABLE_TENNIS_SEASON, TABLE_TENNIS_SEASON_MOBILE],
    [SPORTS_SEASON_SETTING_TYPE.OFF_SEASON]: [TABLE_TENNIS_OFF_SEASON, TABLE_TENNIS_OFF_SEASON_MOBILE],
    [SPORTS_SEASON_SETTING_TYPE.NONE]: [TABLE_TENNIS_OFF_SEASON, TABLE_TENNIS_OFF_SEASON_MOBILE]
  },
  [SPORTS.ICE_HOCKEY]: {
    [SPORTS_SEASON_SETTING_TYPE.SEASON]: [ICE_HOCKEY_SEASON, ICE_HOCKEY_SEASON_MOBILE],
    [SPORTS_SEASON_SETTING_TYPE.OFF_SEASON]: [ICE_HOCKEY_OFF_SEASON, ICE_HOCKEY_OFF_SEASON_MOBILE],
    [SPORTS_SEASON_SETTING_TYPE.NONE]: [ICE_HOCKEY_OFF_SEASON, ICE_HOCKEY_OFF_SEASON_MOBILE]
  },
  [SPORTS.ESPORTS]: {
    [SPORTS_SEASON_SETTING_TYPE.SEASON]: [ESPORTS_SEASON, ESPORTS_SEASON_MOBILE],
    [SPORTS_SEASON_SETTING_TYPE.OFF_SEASON]: [ESPORTS_OFF_SEASON, ESPORTS_OFF_SEASON_MOBILE],
    [SPORTS_SEASON_SETTING_TYPE.NONE]: [ESPORTS_OFF_SEASON, ESPORTS_OFF_SEASON_MOBILE]
  },
  [SPORTS.ETC]: {
    [SPORTS_SEASON_SETTING_TYPE.SEASON]: [ETC_SEASON, ETC_SEASON_MOBILE],
    [SPORTS_SEASON_SETTING_TYPE.OFF_SEASON]: [ETC_OFF_SEASON, ETC_OFF_SEASON_MOBILE],
    [SPORTS_SEASON_SETTING_TYPE.NONE]: [ETC_OFF_SEASON, ETC_OFF_SEASON_MOBILE]
  }
} as const;

const SPORTS_TAB = {
  TICKET: 'TICKET',
  NOTICE: 'NOTICE',
  INFO: 'INFO',
  PRICE: 'PRICE',
  SEAT: 'SEAT'
} as const;
const SPORTS_TABS = [
  { name: '예매하기', code: SPORTS_TAB.TICKET },
  { name: '구단공지', code: SPORTS_TAB.NOTICE }
] as const;

export {
  SCHEDULE,
  SPORTS,
  STATE,
  RESERVE_BANNER,
  OFF_SEASON_TEMPLATE_TYPE,
  SPORTS_SEASON_SETTING_TYPE,
  SPORTS_ICON_INFO,
  SPORTS_SEASON_SETTING_TYPE_ICON,
  SPORTS_TABS,
  SPORTS_TAB
};
