import { Navigate, Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeaderGlobal from '../../components/HTMLHeader/NormalHeader.global';
import { LocalGlobal } from '../../pages/global/pc';

const LocalRouteGlobal = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeaderGlobal>
            <Channel
              pc={<LocalGlobal />}
              // TODO:II:GLOBAL_MOBILE
              mobile={<LocalGlobal />}
            />
          </NormalHeaderGlobal>
        }
      />
      <Route
        path=":category"
        element={
          <NormalHeaderGlobal>
            <Channel
              pc={<LocalGlobal />}
              // TODO:II:GLOBAL_MOBILE
              mobile={<LocalGlobal />}
            />
          </NormalHeaderGlobal>
        }
      />
      <Route path="*" element={<Navigate to=".." />} />
    </Routes>
  );
};

export default LocalRouteGlobal;
