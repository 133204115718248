import { adMoRequests, adPcRequests } from '@tkl-packages/sdk/src/axios/adInterface';

const getHeaderBannerApi = () => adPcRequests.get('new_imgtext/20444@20444_imgtext');
const getShowPromotionBannerApi = () => adPcRequests.get('new_per_layer/560120@560120_perlayer');
const getSportsPromotionBannerApi = () => adPcRequests.get('new_sports_layer/560120@560120_sportslayer');
const getShowLineBannerApi = () => adPcRequests.get('new_per_belt/1120109@1120109_perbelt');
const getSportsLineBannerApi = () => adPcRequests.get('new_sports_belt/1120109@1120109_sportsbelt');
const getExhibitionLineBannerApi = () => adPcRequests.get('n_ex_detail_belt/112096@112096_ex_detailbelt');
const getPerformanceLineBannerApi = () => adPcRequests.get('n_per_detail_belt/112096@112096_per_detailbelt');
const getProductLineBannerApi = () => adPcRequests.get('n_detail_belt/112096@112096_detailbelt');
const getGlobalMainMiddleBannerApi = () =>
  adPcRequests.get(
    'pc_global_main_middleline_1120x109/pc_global_main_middleline_1120x109@pc_global_main_middleline_1120x109'
  );
const getGlobalGnbBannerApi = () => adPcRequests.get('pc_global_gnb_204x44/pc_global_gnb_204x44@pc_global_gnb_204x44');
const getGlobalSubMainBannerApi = () =>
  adPcRequests.get(
    'pc_global_main__servemain_350x464/pc_global_main__servemain_350x464@pc_global_main__servemain_350x464'
  );
const getGlobalSportsSideBannerApi = () =>
  adPcRequests.get(
    'pc_global_sports_skyscraper_180x293/pc_global_sports_skyscraper_180x293@pc_global_sports_skyscraper_180x293'
  );
const getSportsTeamSideBannerApi = () =>
  adPcRequests.get('pc_sports_skyscraper/pc_sports_skyscraper_180x293@pc_sports_skyscraper_180x293');

const getMobileMainCenterFirstApi = () => adMoRequests.get('mo.app_main_belt_1/720150@720150_main_belt_1');
const getMobileMainCenterSecondApi = () => adMoRequests.get('mo.app_main_belt_2/720150@720150_main_belt_2');
const getMobileMainBottomApi = () => adMoRequests.get('mo.app_main_belt_3/720150@720150_main_belt_3');
const getMobileSearchApi = () => adMoRequests.get('mo.app_search_belt/720150@720150_search_belt');

export {
  getHeaderBannerApi,
  getShowPromotionBannerApi,
  getSportsPromotionBannerApi,
  getShowLineBannerApi,
  getSportsLineBannerApi,
  getExhibitionLineBannerApi,
  getPerformanceLineBannerApi,
  getProductLineBannerApi,
  getMobileMainCenterFirstApi,
  getMobileMainCenterSecondApi,
  getMobileMainBottomApi,
  getMobileSearchApi,
  getGlobalMainMiddleBannerApi,
  getGlobalGnbBannerApi,
  getGlobalSubMainBannerApi,
  getSportsTeamSideBannerApi,
  getGlobalSportsSideBannerApi
};
