import { Navigate, Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import SportsHeader from '../../components/HTMLHeader/SportsHeader';
import { SportsTeamGlobal } from '../../pages/global/pc';

const SportsRouteGlobal = () => {
  return (
    <Routes>
      <Route index element={<Navigate to=".." />} />
      <Route path=":categoryId/*">
        <Route
          path=":teamId"
          element={
            <SportsHeader>
              <Channel
                pc={<SportsTeamGlobal />}
                // TODO:II:GLOBAL_MOBILE
                mobile={<SportsTeamGlobal />}
              />
            </SportsHeader>
          }
        />
      </Route>
    </Routes>
  );
};

export default SportsRouteGlobal;
