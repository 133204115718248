import { FC } from 'react';
import { APP_SCHEME, appScheme, callAppScheme } from '@tkl-packages/constants/appScheme';
import { COOKIE_KEY } from '@tkl-packages/sdk/src/axios/constants';
import { GlobalView } from '@tkl-packages/sdk/src/utils';
import { isAppWeb } from '@tkl-packages/sdk/src/validations';
import Cookies from 'js-cookie';

const LoginMobile: FC = () => {
  const appVersion = Cookies.get(COOKIE_KEY.APP_VERSION);
  const onClickPaycoLogin = () => {
    if (isAppWeb()) {
      callAppScheme(APP_SCHEME.LOGIN);
    } else {
      const pcWebPath = GlobalView.replacePCWebServerPath('/auth/login');
      const loginPath = GlobalView.applyPath(pcWebPath);
      window.open(loginPath + `?nextURL=${document.URL}`);
    }
  };

  const onClickFacilityLogin = () => {
    callAppScheme(APP_SCHEME.FACILITY_LOGIN);
  };
  return (
    <section className="m_section section_login">
      <h2 className="login_payco_logo">
        <span className="blind">PAYCO</span>
      </h2>
      <p className="login_desc">
        티켓링크 서비스는 PAYCO로
        <br />
        로그인하여 이용가능합니다.
      </p>
      <div className="login_way_group">
        <div className="common_btn_box">
          <button type="button" className="common_btn btn_secondary btn_medium" onClick={onClickPaycoLogin}>
            PAYCO로 로그인
          </button>
        </div>
        {isAppWeb() && (
          <>
            <div className="login_way_or">
              <span className="text">OR</span>
            </div>
            <div className="common_btn_box">
              <button type="button" className="common_btn btn_ghost btn_medium" onClick={onClickFacilityLogin}>
                구단회원 로그인
              </button>
            </div>
          </>
        )}
      </div>
      {isAppWeb() && (
        <div className="login_app_info">
          <a href={appScheme(APP_SCHEME.OPEN_SOURCE_LICENSE)} className="btn_hyperlink">
            Open Source License
          </a>
          <span className="login_app_version">버전정보 {appVersion}</span>
        </div>
      )}
    </section>
  );
};

export default LoginMobile;
