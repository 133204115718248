import { PropsWithChildren, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import { GoogleAnalytics } from '@tkl-packages/components/src/ticketlink/GA';
import { OgTag } from '@tkl-packages/components/src/ticketlink/OgTag';

/**
 * React-Helmet의 범위 밖의 link 태그 제거 용도로 사용합니다.
 */
export const ScopedHelmet = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  /**
   * @note React-Helmet이 unmount 된 상태에서 link 태그를 남기는 문제를 처리합니다.
   */
  const [active, setActive] = useState(false);
  useEffect(() => {
    setActive(true);
    return () => setActive(false);
  }, []);

  return <Helmet key={location.key}>{active ? children : null}</Helmet>;
};

const PolicyHeaderGlobal = ({ children }: PropsWithChildren) => {
  return (
    <>
      <OgTag />
      <Channel
        pc={
          <ScopedHelmet>
            <link rel="stylesheet" type="text/css" href={`${process.env.CSS_URL}/policy.css`} />
          </ScopedHelmet>
        }
        mobile={
          // TODO:II:GLOBAL_MOBILE 모바일 작업 시, CSS 확인 필요.
          <ScopedHelmet>
            <link rel="stylesheet" type="text/css" href={`${process.env.CSS_URL}/policy.css`} />
          </ScopedHelmet>
        }
      />
      <GoogleAnalytics />
      {children}
    </>
  );
};

export default PolicyHeaderGlobal;
