import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { atom, useAtom } from 'jotai';

const subHeaderProductInfoAtom = atom('');

/**
 * GNB 서브헤더 표시에 이용되는 location 기반 함수들을 반환합니다.
 */
export const useSubHeaderType = () => {
  const location = useLocation();

  const equalUrlPath = useCallback(
    (path: string) => {
      return location.pathname === path ? 'page' : false;
    },
    [location.pathname]
  );

  const includesUrlPath = useCallback(
    (path: string) => {
      return location.pathname.includes(path) ? 'page' : false;
    },
    [location.pathname]
  );

  return { equalUrlPath, includesUrlPath };
};

/**
 * 상품 상세 페이지에서 설정한 상품의 카테고리 정보를 매칭합니다.
 */
export const useSubHeaderProductInfo = () => {
  const location = useLocation();

  const [productInfo, setProductInfo] = useAtom(subHeaderProductInfoAtom);
  useEffect(() => {
    if (!location.pathname.includes('/product/')) {
      setProductInfo('');
    }
  }, [location.pathname, setProductInfo]);

  return { matchProductInfo: (productInfoParam: string) => (productInfoParam === productInfo ? 'page' : false) };
};

/**
 * 상품 상세 페이지에서 상품의 카테고리 정보를 설정합니다.
 * @param productInfo 국내는 `공연`과 `전시` 1뎁스 정보 처리에만 사용, 글로벌은 2뎁스 정보 대부분 사용
 */
export const useUpdateSubHeaderProductInfo = (productInfo: string) => {
  const [, setProductInfo] = useAtom(subHeaderProductInfoAtom);
  useEffect(() => {
    setProductInfo(productInfo);
  }, [productInfo, setProductInfo]);
};
