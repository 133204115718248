import { Navigate, Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeaderGlobal from '../../components/HTMLHeader/NormalHeader.global';

const SearchRouteGlobal = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeaderGlobal>
            {/* TODO:II:GLOBAL_MOBILE */}
            <Channel mobile={undefined} />
            {/* <Channel mobile={ <SearchGlobalMobile /> : */}
          </NormalHeaderGlobal>
        }
      />
      <Route
        path="result"
        element={
          <NormalHeaderGlobal>
            {/* TODO:II:GLOBAL_MOBILE */}
            <Channel mobile={undefined} />
            {/* <Channel mobile={ <SearchResultGlobalMobile /> : */}
          </NormalHeaderGlobal>
        }
      />
      <Route path="*" element={<Navigate to=".." />} />
    </Routes>
  );
};

export default SearchRouteGlobal;
