import { useTranslation } from 'react-i18next';
import { hasContent } from '@tkl-apps/ticketlink/src/utils/product';
import { TabInfoUI } from '@tkl-packages/models';
import { useSportsTabInfoDetail } from '../../hooks/sports/useSportsTeams';

interface props {
  tabInfo: TabInfoUI;
}

const TeamSeatMapMobile = ({ tabInfo }: props) => {
  const { t } = useTranslation();

  const { tabInfoDetail } = useSportsTabInfoDetail(tabInfo);

  return (
    <>
      {hasContent(tabInfoDetail) ? (
        <div className="team_seat">
          <div className="team_seat_guide" dangerouslySetInnerHTML={{ __html: tabInfoDetail }}></div>
        </div>
      ) : (
        <div className="common_data_none">
          <p className="text">{t('좌석도 내용이 없습니다.')}</p>
        </div>
      )}
    </>
  );
};

export default TeamSeatMapMobile;
