import { useCallback, useEffect, useRef, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { Notice } from '@tkl-packages/models';
import { getTeamNoticeApi } from '@tkl-packages/services/src/mapi';
import dayjs from 'dayjs';

interface props {
  teamId: string;
}

const TeamNoticeMobile = ({ teamId }: props) => {
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  const {
    data: teamTopNotice,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteQuery({
    queryKey: [QUERY_KEY.TEAM_NOTICES, teamId],
    queryFn: ({ pageParam = 1 }) => getTeamNoticeApi({ teamId, page: pageParam, itemPerPage: 15 }),
    getNextPageParam: (lastPage) => {
      if (!lastPage.data.paging.currentPage || !lastPage.data.paging.pageEnd || !lastPage.data.paging.pageCount) {
        return undefined;
      }
      if (lastPage.data.paging.currentPage >= lastPage.data.paging.pageEnd) return undefined;
      const nextPage = lastPage.data.paging.currentPage + 1;
      return nextPage <= lastPage.data.paging.pageCount ? nextPage : undefined;
    },
    enabled: teamId !== '',
    initialPageParam: 1,
    select: (data) => data.pages.flatMap((page) => page.data?.noticeList ?? [])
  });

  const [openedNotice, setOpenedNotice] = useState<Partial<Notice>>();
  const toggleOpenedNotice = (notice: Notice) => {
    setOpenedNotice(openedNotice?.noticeId === notice.noticeId ? {} : notice);
  };

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [target] = entries;

      if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage]
  );

  useEffect(() => {
    const element = loadMoreRef.current;

    const observer = new IntersectionObserver(handleObserver, {
      threshold: 0.1
    });
    if (element) {
      observer.observe(element);
    }

    return () => {
      observer.disconnect();
    };
  }, [handleObserver]);

  return (
    <>
      {teamTopNotice && teamTopNotice.length > 0 ? (
        <div className="team_notice">
          <ul className="popup_notice_list">
            {teamTopNotice.map((notice: Notice) => (
              <li key={notice.noticeId} className="popup_notice_item" onClick={() => toggleOpenedNotice(notice)}>
                <button
                  type="button"
                  className="notice_toggle_btn"
                  aria-expanded={openedNotice?.noticeId === notice.noticeId}
                >
                  <strong className="notice_title">{notice.noticeTitle}</strong>
                  <span className="notice_type">공지</span>
                  <span className="notice_date">{dayjs(notice.noticeDate).tz().format('YYYY.MM.DD')}</span>
                </button>
                <div className="notice_cont">
                  <div dangerouslySetInnerHTML={{ __html: notice.noticeContent }}></div>
                </div>
              </li>
            ))}
            {/* TODO:II 인피니티 쿼리를 위한 최소한의 높이 설정 필요 */}
            <div ref={loadMoreRef} style={{ height: '1px' }} />
          </ul>
        </div>
      ) : (
        <div className="common_data_none">
          <p className="text">구단공지 내용이 없습니다.</p>
        </div>
      )}
    </>
  );
};

export default TeamNoticeMobile;
