import { SearchKeywordRequest, SearchProductRequest } from '@tkl-packages/models';
import { Response } from '@tkl-packages/sdk/src/axios/axios.type';
import { requests } from '@tkl-packages/sdk/src/axios/mapiInterface';

/**
 * 인기 검색어 리스트를 반환합니다.
 */
const getPopularSearchApi = (): Promise<Response> => requests.get('search/popular/list', undefined, 5000);
/**
 * `params`에 따른 추천 검색어를 반환합니다.
 */
const getSearchKeywordsApi = (params: SearchKeywordRequest): Promise<Response> =>
  requests.get('search/suggestion', params);
/**
 * 검색 결과를 반환합니다.
 * - 검색 결과 리스트
 * - 특정 검색어의 리디렉션 Full Url "https://new-m.ticketlink.co.kr/sports/137"
 */
const getSearchProductsApi = (params: SearchProductRequest): Promise<Response> => requests.get('search/list', params);

export { getPopularSearchApi, getSearchKeywordsApi, getSearchProductsApi };
