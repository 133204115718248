import { RefObject, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import useScroll from '@tkl-packages/hooks/src/useScroll';
import { GlobalView } from '@tkl-packages/sdk/src/utils';
import { isLibraryMode } from '@tkl-packages/sdk/src/vite';

interface props {
  footerRef: RefObject<HTMLElement>;
}

const FloatingButtonsGlobal = ({ footerRef }: props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isStuck, setIsStuck] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const isSportTeamPage = !!matchPath(GlobalView.applyPath('/sports/:categoryId/:teamId'), pathname);
  const navigate = useNavigate();

  const refresh = () => {
    window.location.reload();
  };

  const moveToGuide = () => {
    // 안내 - 예매안내 페이지로 이동합니다.
    const guidePath = GlobalView.applyPath('/help/guide/reservation/baseball');
    if (isLibraryMode()) window.location.href = guidePath;
    else navigate(guidePath);
  };

  const moveToTop = () => {
    window.scrollTo({ top: 0 });
  };

  const onScroll = useCallback(() => {
    const scrollHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
    const scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
    const scrollBottom = scrollHeight - window.innerHeight - scrollTop;
    const nowIsActive = scrollTop !== 0;
    const nowIsStuck = !!(footerRef.current && scrollBottom - footerRef.current.clientHeight + 80 < 20);

    if (nowIsActive !== isActive) {
      setIsActive(nowIsActive);
    }

    if (nowIsStuck !== isStuck) {
      setIsStuck(nowIsStuck);
    }
  }, [footerRef, isActive, isStuck]);

  useScroll(onScroll);

  return (
    <div className={`floating_btns ${isStuck ? 'is-stuck' : ''}`}>
      {isSportTeamPage && (
        <button type="button" className="btn_page_refresh" style={{ display: 'inline-block' }} onClick={refresh}>
          <span className="blind">{t('새로고침')}</span>
        </button>
      )}

      <button type="button" className={`btn_move_top ${isActive ? 'is-active' : ''}`} onClick={moveToTop}>
        <span className="blind">{t('맨 위로 이동하기')}</span>
      </button>

      <button type="button" className={`btn_global_guide`} onClick={moveToGuide}>
        {t('GLOBAL GUIDE')}
      </button>
    </div>
  );
};

export default FloatingButtonsGlobal;
