import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import category from '@tkl-packages/assets/toolbar_category.json';
import home from '@tkl-packages/assets/toolbar_home.json';
import my from '@tkl-packages/assets/toolbar_my.json';
import search from '@tkl-packages/assets/toolbar_search.json';
import ticket from '@tkl-packages/assets/toolbar_ticket.json';
import Lottie from '@tkl-packages/components/src/ticketlink/Navigation/Lottie';
import { GlobalView } from '@tkl-packages/sdk/src/utils';
import { isWeb } from '@tkl-packages/sdk/src/validations';

const NavigationGlobal: FC<PropsWithChildren<{ isHide: boolean }>> = ({ children, isHide }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const pcWebPath = GlobalView.replacePCWebServerPath('/search');
  const searchPath = GlobalView.applyPath(pcWebPath);

  const homePath = GlobalView.applyPath('/home');
  const isHome = pathname === homePath;
  return (
    <nav className={`m_toolbar ${isHide ? 'is-hide' : ''}`}>
      {isWeb() && (
        <ul className="toolbar_menu">
          <li className="toolbar_item">
            <NavLink
              to={GlobalView.applyPath('/category')}
              className={({ isActive }) => 'toolbar_link ' + (isActive ? 'is-active' : '')}
            >
              {({ isActive }) => (
                <>
                  <Lottie autoplay={isActive} className="icon_toolbar toolbar_category" animationData={category} />
                  <span className="toolbar_text">{t('카테고리')}</span>
                </>
              )}
            </NavLink>
          </li>
          <li className="toolbar_item">
            <NavLink to={searchPath} className={({ isActive }) => 'toolbar_link ' + (isActive ? 'is-active' : '')}>
              {({ isActive }) => (
                <>
                  <Lottie autoplay={isActive} className="icon_toolbar toolbar_search" animationData={search} />
                  <span className="toolbar_text">{t('검색')}</span>
                </>
              )}
            </NavLink>
          </li>
          <li className="toolbar_item">
            <a href={homePath} className={`toolbar_link ${isHome ? 'is-active' : ''}`}>
              <Lottie autoplay={isHome} className="icon_toolbar toolbar_home" animationData={home} />
              <span className="toolbar_text">{t('홈')}</span>
            </a>
          </li>
          <li className="toolbar_item">
            <NavLink
              to={GlobalView.applyPath('/ticket')}
              className={({ isActive }) => 'toolbar_link ' + (isActive ? 'is-active' : '')}
            >
              {({ isActive }) => (
                <>
                  <Lottie autoplay={isActive} className="icon_toolbar toolbar_ticket" animationData={ticket} />
                  <span className="toolbar_text">{t('티켓')}</span>
                </>
              )}
            </NavLink>
          </li>
          <li className="toolbar_item">
            <NavLink
              to={GlobalView.applyPath('/my')}
              className={({ isActive }) => 'toolbar_link ' + (isActive ? 'is-active' : '')}
            >
              {({ isActive }) => (
                <>
                  <Lottie autoplay={isActive} className="icon_toolbar toolbar_my" animationData={my} />
                  <span className="toolbar_text">{t('마이')}</span>
                </>
              )}
            </NavLink>
          </li>
        </ul>
      )}
      {children}
    </nav>
  );
};

export default NavigationGlobal;
