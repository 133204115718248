import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LOCALE, LANGUAGES } from '@tkl-packages/constants/locale';
import { getGlobalChannel } from '@tkl-packages/sdk/src/utils';
import useScroll from '../../../../hooks/src/useScroll';

interface props {
  title?: string;
  useTransparency?: boolean;
  useTransparencyTitle?: boolean;
  useShareBtn?: boolean;
  useLanguageBtn?: boolean;
  onClickBackBtn?(): void;
  onClickCloseBtn?(): void;
  onClickShareBtn?(): void;
  onClickLanguageBtn?(): void;
}

const HeaderMobile = ({
  title,
  useTransparency = false,
  useTransparencyTitle = false,
  useShareBtn = false,
  useLanguageBtn = false,
  onClickBackBtn,
  onClickCloseBtn,
  onClickShareBtn,
  onClickLanguageBtn
}: props) => {
  const { t } = useTranslation();
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const currentLanguage = useMemo(() => {
    const languageMap = new Map(LANGUAGES.map((lang) => [lang.code, lang]));
    return languageMap.get(getGlobalChannel()) ?? languageMap.get(DEFAULT_LOCALE);
  }, []);

  const onScroll = () => {
    if (useTransparency) {
      setIsScrolled(window.scrollY > 0);
    }
  };

  useScroll(onScroll);

  return (
    <header className={`m_header ${useTransparency && !isScrolled ? 'type_transparent' : ''}`}>
      <div className="header_inner">
        <h1 className={`header_title ${useTransparency && useTransparencyTitle && !isScrolled ? 'blind' : ''}`}>
          {title}
        </h1>
        <div className="header_util_left">
          {onClickBackBtn && (
            <button type="button" className="header_button button_back" onClick={onClickBackBtn}>
              <span className="blind">{t('이전 페이지')}</span>
            </button>
          )}
        </div>
        <div className="header_util_right">
          {onClickCloseBtn && (
            <button type="button" className="header_button button_close" onClick={onClickCloseBtn}>
              <span className="blind">{t('닫기')}</span>
            </button>
          )}
          {useShareBtn && (
            <button type="button" className="header_button button_share" onClick={onClickShareBtn}>
              <span className="blind">{t('공유하기')}</span>
            </button>
          )}
          {useLanguageBtn && (
            <button type="button" className="header_button btn_dropdown button_lang" onClick={onClickLanguageBtn}>
              <span className={`ico_lang ${currentLanguage?.iconClassName}`}></span> {currentLanguage?.name}
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

export default HeaderMobile;
