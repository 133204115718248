import { getMobileBannerLinkInfo } from '@tkl-apps/ticketlink/src/utils/banner';
import SwiperWrapper from '@tkl-packages/components/src/ticketlink/Banner/SwiperWrapper';
import { MergedBanner } from '@tkl-packages/models';
import { SwiperSlide } from 'swiper/react';

interface props {
  mergedBanners: (MergedBanner | null)[];
}

// 배너 목록 루프를 시작할 아이템 갯수
const BANNER_LOOP_MIN_COUNT = 2;

const SportsBannerMobile = ({ mergedBanners }: props) => {
  const getBannerLinkProps = (banner: MergedBanner) => {
    if (!banner.appUrl) {
      return {
        url: banner.url || '',
        target: '_self'
      };
    }

    return getMobileBannerLinkInfo({
      appTarget: banner.appTarget || '',
      appUrl: banner.appUrl || '',
      webTarget: banner.target || '',
      webUrl: banner.url || ''
    });
  };

  return (
    <SwiperWrapper className="team_banner" pagination={true} loop={mergedBanners.length >= BANNER_LOOP_MIN_COUNT}>
      {mergedBanners.map((mergedBanner) => {
        if (!mergedBanner) return null;

        const { url, target } = getBannerLinkProps(mergedBanner);

        return (
          <SwiperSlide tag="li" key={mergedBanner.id}>
            <a href={url} target={target} className="team_banner_link">
              <img src={mergedBanner.imageUrl} alt={mergedBanner.title} className="team_banner_img" />
            </a>
          </SwiperSlide>
        );
      })}
    </SwiperWrapper>
  );
};

export default SportsBannerMobile;
