import { Navigate, Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import PolicyHeaderGlobal from '../../components/HTMLHeader/PolicyHeader.global';
import { PrivacyPolicyGlobal, UsagePolicyGlobal } from '../../pages/global/pc';

const PolicyRouteGlobal = () => {
  return (
    <Routes>
      <Route index element={<Navigate to=".." />} />
      {/* 개인정보 처리방침 */}
      <Route
        path="privacy"
        element={
          <PolicyHeaderGlobal>
            <Channel
              pc={<PrivacyPolicyGlobal />}
              // TODO:II:GLOBAL_MOBILE
              mobile={<PrivacyPolicyGlobal />}
              // mobile={ <PrivacyPolicyGlobalMobile /> }
            />
          </PolicyHeaderGlobal>
        }
      />
      {/* 이용약관 */}
      <Route
        path="usage"
        element={
          <PolicyHeaderGlobal>
            <Channel
              pc={<UsagePolicyGlobal />}
              // TODO:II:GLOBAL_MOBILE
              mobile={<UsagePolicyGlobal />}
              // mobile={ <UsagePolicyGlobalMobile /> }
            />
          </PolicyHeaderGlobal>
        }
      />

      <Route path="*" element={<Navigate to=".." />} />
    </Routes>
  );
};

export default PolicyRouteGlobal;
