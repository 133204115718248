import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { APP_SCHEME, callAppScheme } from '@tkl-packages/constants/appScheme';
import { SALE_STATE } from '@tkl-packages/constants/product';
import { CERTIFIED_DEVICE_STATUS } from '@tkl-packages/constants/user';
import { CertifiedDeviceStatus, SaleState, ScheduleMatch, State as SportsSaleState } from '@tkl-packages/models';
import { isPc, isWeb } from '@tkl-packages/sdk/src/validations';
import dayjs from 'dayjs';
import { LoginContext } from '../Context/Login';

interface props {
  isAuthReinforced: boolean;
  saleStatus: SaleState | SportsSaleState;
  className: string;
  onReserve: () => void;
  reserveOpenDate?: number | string;
  certifiedDeviceStatusCode?: null | CertifiedDeviceStatus;
  scheme?: string;
  schedule?: ScheduleMatch;
}
const ReserveButton = ({
  isAuthReinforced,
  saleStatus,
  className,
  onReserve,
  reserveOpenDate,
  certifiedDeviceStatusCode,
  scheme = 'ticketlink://deviceAuth'
}: props) => {
  const { needLogin } = useContext(LoginContext);
  const { t } = useTranslation();

  const onClickAppOnly = () => {
    if (isPc()) {
      alert(t('티켓링크 앱에서만 이용 가능합니다.'));
    } else {
      if (confirm(t('티켓링크 앱에서 기기 인증 후 이용 가능합니다.\n앱으로 이동 하시겠습니까?'))) {
        window.location.href = `https://ticketlink.dn.toastoven.net/mobile/sports/bridge.nhn?scheme=${scheme}`;
      }
    }
  };

  const onClickDeviceAuth = needLogin(() => {
    callAppScheme(APP_SCHEME.DEVICE_AUTH);
  });

  const onClickDeviceDiscord = () => {
    if (confirm(t('기기 인증 정보가 일치하지 않아 예매가 어렵습니다.\n기기 인증을 다시 하겠습니까?'))) {
      callAppScheme(APP_SCHEME.DEVICE_AUTH);
    }
  };

  const clickableStyle = useMemo(
    () => ({
      cursor: 'pointer'
    }),
    []
  );

  if (saleStatus === SALE_STATE.END) {
    return (
      <a className={`common_btn btn_primary ${className}`} aria-disabled="true">
        {t('판매종료')}
      </a>
    );
  }

  if (isAuthReinforced && isWeb()) {
    return (
      <a className={`common_btn btn_primary ${className} disabled`} aria-disabled="false" onClick={onClickAppOnly}>
        {t('앱 전용 상품')}
      </a>
    );
  }

  if (isAuthReinforced && certifiedDeviceStatusCode !== CERTIFIED_DEVICE_STATUS.DEVICE_ACCORD) {
    return (
      <>
        {certifiedDeviceStatusCode === CERTIFIED_DEVICE_STATUS.DEVICE_DISCORD ? (
          <a
            className={`common_btn ${className} btn_secondary disabled`}
            aria-disabled="false"
            onClick={onClickDeviceDiscord}
          >
            <span>{t('예매불가(기기 정보 불일치)')}</span>
          </a>
        ) : (
          <a className={`common_btn ${className} btn_secondary`} aria-disabled="false" onClick={onClickDeviceAuth}>
            {t('기기 인증하기')}
          </a>
        )}
      </>
    );
  }

  if (saleStatus === SALE_STATE.BEFORE) {
    return (
      <a className={`common_btn btn_primary ${className} plan_sale`} aria-disabled="true">
        {`${reserveOpenDate ? `${dayjs(Number(reserveOpenDate)).tz().format('YYYY.MM.DD (dd) HH:mm')} ${t('오픈예정')}` : t('판매예정')}`}
      </a>
    );
  }

  return (
    <a className={`common_btn btn_primary ${className}`} onClick={onReserve} style={clickableStyle}>
      {t('예매하기')}
    </a>
  );
};

export default ReserveButton;
