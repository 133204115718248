import { Navigate, Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeader from '../../components/HTMLHeader/NormalHeader';
import { FacilityMemberCard } from '../../pages/facility';
import { RenewalTicketMobile4Sns } from '../../pages/mobile';

const MobileRoute = () => {
  return (
    <Routes>
      <Route
        path="member-card"
        element={
          <NormalHeader>
            <Channel facility={<FacilityMemberCard />} />
          </NormalHeader>
        }
      />
      <Route
        path="ticket"
        element={
          <NormalHeader disableGoogleAnalytics>
            <Channel mobile={<RenewalTicketMobile4Sns />} />
          </NormalHeader>
        }
      />
      <Route path="*" element={<Navigate to=".." />} />
    </Routes>
  );
};

export default MobileRoute;
